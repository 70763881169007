<div
  class="point__control"
  [class.point__control--full]="ppFull"
  *ngIf="ppPoint"
  (click)="$event.stopPropagation()"
>
  <div class="point__control-left" data-m-target="Point previous & next buttons">
    <pp-button (ppAction)="back()" ppStyle="text" *ngIf="isShowingRemindersFullScreen()">
      <pp-icon
        [ppSrc]="EIconPath.ARROW_LEFT"
        ppColor="primary-600"
        [ppDisplayContents]="true"
      ></pp-icon>
      {{ 'back_to_point' | ppTranslate }}
    </pp-button>

    <div class="pointControl__arrows" *ngIf="!isShowingRemindersFullScreen()">
      <div
        ppTooltip
        id="previousPointBtn_halfModal"
        class="point__control-btn"
        [ngClass]="{
          'point__control-btn--disabled': ppAttachmentUploading || !nextPointExists,
        }"
        ppTitle="Previous Point"
        *ngIf="!ppNew"
        (click)="prevPoint()"
        data-test="previousPointButton"
      >
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_UP_ARROW_24"
          ppColor="grey-500"
          ppClass="setSize24"
        ></pp-icon>
      </div>

      <div
        ppTooltip
        id="nextPointBtn_halfModal"
        class="point__control-btn"
        [ngClass]="{
          'point__control-btn--disabled': ppAttachmentUploading || !nextPointExists,
        }"
        ppTitle="Next Point"
        (click)="nextPoint()"
        data-test="nextPointButton"
        *ngIf="!ppNew && !isShowingRemindersFullScreen()"
      >
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_DOWN_ARROW_24"
          ppColor="grey-500"
          ppClass="setSize24"
        ></pp-icon>
      </div>
    </div>

    <div class="pointControl__line" *ngIf="!ppNew && !isShowingRemindersFullScreen()"></div>

    <pp-point-seen-by
      class="hide--mobile"
      [ppSeenBy]="ppPointDetails?.viewersByIds"
      *ngIf="ppPoint && hasWorkspaces && !ppNew && !isShowingRemindersFullScreen()"
    ></pp-point-seen-by>
  </div>

  <div class="point__control-right">
    <div class="pointControl__rightButtons">
      <div
        ppTooltip
        id="redFlag_halfModal"
        data-m-target="Point Flag Button"
        data-test="flagButton"
        class="point__control-btn point__control-btn--border"
        (click)="toggleFlag()"
        *ngIf="!ppNew && ppDetailsFetched"
        [ngClass]="{ 'point__control-btn--disabled': !canEditFlag }"
        [ppTitle]="
          ppPointDetails && ppPoint.flagged
            ? ('remove_flag' | ppTranslate)
            : ('add_flag' | ppTranslate)
        "
      >
        <pp-icon
          [ppSrc]="
            ppPoint.flagged
              ? EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_24
              : EIconPath.ICON_ACTIVITIES_RED_FLAG_24
          "
          [ppDisplayContents]="true"
        ></pp-icon>
      </div>
      <div
        ppTooltip
        data-test="subscribeButton"
        id="subscribe_halfModal"
        data-m-target="Subscribe To Point Button"
        class="point__control-btn point__control-btn--border"
        *ngIf="!ppNew && ppDetailsFetched"
        [class.point__control-btn--blue]="ppPointDetails?.pushNotificationsSubscription"
        [ppTitle]="
          ppPointDetails?.pushNotificationsSubscription
            ? 'Subscribed to point'
            : 'Subscribe to point'
        "
        (click)="toggleSubscription()"
      >
        <pp-icon
          ppClass="setSize24"
          [ppColor]="ppPointDetails?.pushNotificationsSubscription ? 'white' : 'grey-500'"
          [ppSrc]="
            ppPointDetails?.pushNotificationsSubscription
              ? EIconPath.ICON_MISC_BELL_PLUS_24
              : EIconPath.ICON_MISC_BELL_24
          "
        />
      </div>

      <div
        ppTooltip
        id="expandModalBtn_halfModal"
        data-m-target="Expand point button"
        data-test="expandCompressButton"
        *ngIf="!ppFull || (ppFull && !ppNew)"
        class="point__control-btn point__control-btn--border"
        [ngClass]="{ 'point__control-btn--disabled': ppAttachmentUploading }"
        [ppTitle]="ppFull ? ('collapse_point' | ppTranslate) : ('expand_point' | ppTranslate)"
        (click)="toggleModal()"
      >
        <pp-icon
          [ppSrc]="ppFull ? EIconPath.ICON_MISC_CLOSE_ARROWS_24 : EIconPath.ICON_MISC_OPEN_24"
          class="point__control-icon--animated"
          ppColor="grey-500"
          ppClass="setSize24"
        />
      </div>
      <div
        ppTooltip
        [ppTitle]="'reminders' | ppTranslate"
        id="reminder_halfModal"
        data-test="reminderButton"
        data-m-target="Point Reminder Button"
        class="point__control-btn point__control-btn--border"
        (click)="viewReminders()"
        *ngIf="!ppNew && ppDetailsFetched"
      >
        <pp-icon
          ppClass="setSize24"
          ppColor="grey-500"
          [ppSrc]="EIconPath.ICON_MISC_REMINDER_ICON_24"
        />
      </div>

      <div
        ppTooltip
        id="openInSiteBtn_halfModal"
        data-m-target="Open point in site button"
        class="point__control-btn point__control-btn--border"
        [ngClass]="{ 'point__control-btn--disabled': ppAttachmentUploading }"
        [ppTitle]="'open_in_site' | ppTranslate"
        (click)="openInSite()"
        data-test="expandCompressButton"
        *ngIf="isTimeline || isOverview"
      >
        <img [src]="EIconPath.POINT_INTERACTION_OPEN_IN_SITE" />
      </div>
    </div>

    <pp-point-options
      [ppPointId]="ppPoint._id"
      [ppFull]="ppFull"
      [ppNew]="ppNew"
      [ppWorkspaceId]="ppPoint.workspaceRef.id"
    ></pp-point-options>

    <div class="pointControl__line pointControl__line--big"></div>

    <div
      ppTooltip
      id="closeModalBtn_halfModal"
      class="point__control-btn"
      [ngClass]="{ 'point__control-btn--disabled': ppAttachmentUploading }"
      [ppTitle]="'close_point' | ppTranslate"
      (click)="hide()"
      data-test="closePoint"
    >
      <img
        [src]="EIconPath.INTERACTION_CLOSE"
        class="point__control-icon--close"
        id="closeModalBtn_icon"
      />
    </div>
  </div>
</div>
