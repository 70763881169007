import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterAssignee } from '../../models/advanced-filter.model';

export function filterAssigneeAdvanced(point: TPoint, filter: TAdvancedFilterAssignee): boolean {
  const fieldValue = point.assignees;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_ANY_OF:
      return filter.value.some((value) => fieldValue.includes(value));
    case EAdvancedFilterOptions.IS:
      return filter.value.every((value) => fieldValue.includes(value));
    case EAdvancedFilterOptions.IS_NONE_OF:
      return !filter.value.some((value) => fieldValue.includes(value));
    case EAdvancedFilterOptions.IS_NOT:
      return !filter.value.every((value) => fieldValue.includes(value));
    case EAdvancedFilterOptions.IS_ALL_OF:
      return filter.value.every((value) => fieldValue.includes(value));
    case EAdvancedFilterOptions.IS_EMPTY:
      return fieldValue.length === 0;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return fieldValue.length > 0;
  }
}
