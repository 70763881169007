import { TPoint } from 'src/app/project/modules/points/points.model';
import { TReminder } from '../../reminders/reminders.consts';
import { TFilters } from '../site-filter.model';

export function checkReminders(point: TPoint, filters: TFilters, reminders: TReminder[]): boolean {
  const pointReminders = reminders.filter((reminder) => reminder.pointId === point._id);

  if (!filters.reminders?.startDate && !filters.reminders?.endDate) {
    return true;
  }

  if ((filters.reminders?.startDate || filters.reminders?.endDate) && !pointReminders.length) {
    return false;
  }

  const startDate = filters.reminders?.startDate;
  const endDate = filters.reminders?.endDate;

  for (let reminder of pointReminders) {
    const reminderDate = reminder.timestampEpochMillis;

    const afterStartDate = !startDate || reminderDate >= startDate;
    const beforeEndDate = !endDate || reminderDate <= endDate;

    if (afterStartDate && beforeEndDate) {
      return true;
    }
  }

  return false;
}
