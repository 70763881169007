<pp-button ppStyle="primary" ppSize="small">
  <label
    class="settingsNewSite__upload-button"
    [class.settingsNewSite__upload-button--disabled]="false"
    for="excel"
  >
    {{ 'create_list_from_excel' | ppTranslate }}
  </label>

  <pp-upload
    id="excel--update"
    [ppId]="'excel'"
    (ppUpload)="emitAction($event)"
    [ppDisabled]="false"
  >
  </pp-upload>
</pp-button>
