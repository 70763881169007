<div
  [attr.aria-hidden]="
    {} | calendarA11y: (daysInWeek === 1 ? 'hideDayHourSegment' : 'hideWeekHourSegment')
  "
  class="cal-hour-segment"
  [style.height.px]="80"
  [class.cal-hour-start]="ppSegment.isStart"
  [class.cal-after-hour-start]="!ppSegment.isStart"
  [ngClass]="ppSegment.cssClass"
>
  <div class="cal-time">
    {{ displayDate }}
  </div>
</div>
