import { Component, effect, Input, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { CalendarView } from 'angular-calendar';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/project/services/translation.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TReminder } from '../reminders.consts';
import { RemindersService } from '../reminders.service';
import { TReminderEvent } from './reminder-calendar-consts';
import { SiteFilterDataService } from '../../filters/site-filter-data-service/site-filter-data.service';
import dayjs from 'dayjs';

@Component({
  selector: 'pp-reminders-calendar',
  templateUrl: './reminders-calendar.component.html',
  styleUrl: './reminders-calendar.component.scss',
})
export class RemindersCalendarComponent {
  @ViewChild('calendar', { static: true }) calendar: ElementRef<HTMLElement>;
  @ViewChild('periodView', { read: ElementRef }) dayView: ElementRef;

  @Input() ppDataFetched = false;

  EIconPath = EIconPath;
  CalendarView = CalendarView;
  viewDate = new Date();
  calendarView = CalendarView.Month;
  events: TReminderEvent[] = [];
  locale: string = 'en';
  pointsFiltered: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    private remindersService: RemindersService,
    private translationService: TranslationService,
    private siteFilterDataService: SiteFilterDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.locale = this.translationService.getLocale();
    this.setReminders(this.remindersService.getReminders());

    this.remindersService.remindersChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reminders) => {
        this.setReminders(reminders);
      });

    effect(() => {
      const filters = this.siteFilterDataService.getFiltersChangedSignal()();
      this.pointsFiltered = filters.numberOfFilters > 0;
    });
    this.route.queryParams.subscribe((params) => {
      this.calendarView = params.mode;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof ChildActivationEnd) this.scrollToContentInView();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    this.scrollToContentInView();
  }

  onViewChange(view: CalendarView): void {
    if (view !== CalendarView.Day) {
      this.calendar.nativeElement.parentElement.parentElement.scrollTop = 0;
    }

    this.scrollToContentInView();
  }

  scrollToContentInView(): void {
    setTimeout(() => {
      const container = this.dayView?.nativeElement as HTMLElement;
      const timeMarker = container?.querySelector('.cal-current-time-marker') as HTMLElement;

      if (container) {
        const target = timeMarker
          ? timeMarker
          : (container?.querySelector('.calendarEvent ') as HTMLElement);

        if (target) {
          target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          const targetHeight =
            ((dayjs().hour() * 60 + dayjs().minute()) / 1440) * container.scrollHeight;
          container.scrollTop = targetHeight - container.clientHeight / 2;
        }
      }
    });
  }

  private setReminders(reminders: TReminder[]) {
    this.events = reminders.map((reminder) => {
      return {
        title: reminder.name,
        start: new Date(reminder.timestampEpochMillis),
        reminder: reminder,
      };
    });
  }
}
