import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterCreatedBy } from '../../models/advanced-filter.model';

export function filterCreatedByAdvanced(point: TPoint, filter: TAdvancedFilterCreatedBy): boolean {
  const fieldValue = point.header.createdBy.id;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_ANY_OF:
    case EAdvancedFilterOptions.IS:
      return filter.value.includes(fieldValue);
    case EAdvancedFilterOptions.IS_NOT:
    case EAdvancedFilterOptions.IS_NONE_OF:
      return !filter.value.includes(fieldValue);
  }
}
