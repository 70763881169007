<pp-modal [ppNoHeader]="true" [ppNoFooter]="true" [ppWidth]="375">
  <ng-container body *ngIf="!isClosing; else closeWarningBody">
    <div *ngIf="isEditing" class="reminderModal__container">
      <span class="reminderModal__inputLabels">{{ 'reminder_name' | ppTranslate }}</span>
      <div class="inputContainer">
        <input
          required
          data-test="reminderNameInput"
          class="reminderModal__inputFields reminderModal__inputPadding"
          [placeholder]="'name' | ppTranslate"
          [(ngModel)]="nameInput"
        />
      </div>
      <span class="reminderModal__inputLabels">{{ 'description' | ppTranslate }}</span>
      <div class="inputContainer">
        <textarea
          rows="1"
          data-test="descriptionInput"
          class="reminderModal__inputFields reminderModal__inputPadding reminderModal__descriptionInput"
          [placeholder]="'add_a_description' | ppTranslate"
          [(ngModel)]="descriptionInput"
        ></textarea>
      </div>

      <span class="reminderModal__inputLabels">{{ 'point' | ppTranslate }}</span>
      <div class="reminderModal__linkToPoint" data-test="linkedPointSelectList">
        {{ pointTitle }}
      </div>

      <div class="reminderModal__dateTimeFieldContainer">
        <div class="reminderModal__splitInputField">
          <span class="reminderModal__inputLabels">{{ 'date' | ppTranslate }}</span>
          <pp-datepicker
            data-test="dateSelect"
            class="reminderModal__inputFields"
            [ppDate]="dateInput()"
            (ppSelect)="setDateInput($event)"
            [ppShowCalendarIcon]="true"
          ></pp-datepicker>
        </div>
        <div class="reminderModal__splitInputField">
          <span class="reminderModal__inputLabels">{{ 'time' | ppTranslate }}</span>
          <pp-point-reminder-modal-time-input [(ppTime)]="timeInput">
          </pp-point-reminder-modal-time-input>
        </div>
      </div>
    </div>

    <div *ngIf="!isEditing" class="reminderModal__container">
      <div class="reminderModal__closeIcon">
        <pp-icon
          [ppSrc]="EIconPath.ICON_CLOSE_24"
          ppColor="grey-600"
          class="saveIndicator__clear"
          ppClass="setSize24"
          (click)="close()"
        ></pp-icon>
      </div>
      <span class="reminderModal__viewName">{{ reminder.name }}</span>

      <span class="reminderModal__viewLabel" *ngIf="reminder.description">{{
        'description' | ppTranslate
      }}</span>
      <span
        class="reminderModal__viewValue reminderModal__description"
        *ngIf="reminder.description"
      >
        {{ reminder.description }}</span
      >

      <span class="reminderModal__viewLabel">{{ 'point' | ppTranslate }}</span>
      <div class="reminderModal__viewPointContainer" (click)="viewPoint()">
        <div class="reminderModal__priority" [ngClass]="getPriorityClass(reminder.pointPriority)">
          {{ priorityName }}
        </div>
        <pp-icon [ppSrc]="statusIconSrc" ppClass="setSize14" ppColor="grey-500"></pp-icon>
        <span
          class="reminderModal__pointTitle"
          ppTooltip
          [ppTitle]="reminder.pointTitle"
          [ppTrimTooltip]="true"
          >{{ reminder.pointTitle }}</span
        >
      </div>

      <div class="reminderModal__dateTimeFieldContainer">
        <div class="reminderModal__splitInputField">
          <span class="reminderModal__viewLabel">{{ 'date' | ppTranslate }}</span>
          <span class="reminderModal__viewValue">{{ date }}</span>
        </div>
        <div class="reminderModal__splitInputField">
          <span class="reminderModal__viewLabel">{{ 'time' | ppTranslate }}</span>
          <span class="reminderModal__viewValue">{{ time }}</span>
        </div>
      </div>
      <div class="reminderModal__snoozed" *ngIf="isSnoozed">
        <span class="reminderModal__viewLabel">{{ 'snoozed' | ppTranslate }}</span>
        <div class="reminderModal__snooze">
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_SNOOZE_18"
            class="reminderSnoozeIcon"
            ppClass="setSize18"
            ppColor="inherit"
          ></pp-icon
          ><span>{{ reminderSnoozeTime }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="isEditing" class="reminderModal__actionButtonsRow">
      <pp-button
        class="reminderModal__actionButton"
        data-test="cancelReminderButton"
        ppStyle="secondary"
        ppSize="wide"
        (ppAction)="cancel()"
        >{{ 'cancel' | ppTranslate }}</pp-button
      >
      <pp-button
        data-test="setReminderButton"
        class="reminderModal__actionButton"
        (ppAction)="submit()"
        ppStyle="primary"
        ppSize="wide"
        [ppDisabled]="!nameInput().trim() || !isEdited"
        >{{ isNew ? ('set_reminder' | ppTranslate) : ('save' | ppTranslate) }}</pp-button
      >
    </div>

    <div *ngIf="!isEditing" class="reminderModal__actionButtons">
      <div class="reminderModal__actionButtonsRow">
        <pp-button
          data-test="editReminderButton"
          class="reminderModal__actionButton"
          ppStyle="secondary"
          ppSize="wide"
          (ppAction)="toggleEdit()"
          >{{ 'edit' | ppTranslate }}</pp-button
        >
        <pp-button
          data-test="dismissReminderButton"
          class="reminderModal__actionButton"
          (ppAction)="dismiss()"
          ppStyle="primary"
          ppSize="wide"
          [ppDisabled]="reminder.dismissed"
          >{{ 'dismiss' | ppTranslate }}</pp-button
        >
      </div>
      <button
        class="reminderModal__delete"
        (click)="delete()"
        ppStyle="text"
        data-test="deleteReminderButton"
      >
        <pp-icon [ppSrc]="EIconPath.ICON_BIN_14" ppClass="setSize14" ppColor="red"></pp-icon
        >{{ 'Delete' | ppTranslate }}
      </button>
    </div>
  </ng-container>

  <ng-template body #closeWarningBody>
    <div class="reminderModal__contentPadding">
      <div class="reminderModal__cancelConfirmTextContainer">
        <p class="reminderModal__confirmCancelHeader">
          {{
            isDeleting
              ? ('delete_reminder_confirm' | ppTranslate)
              : ('unsaved_changes' | ppTranslate)
          }}
        </p>
        <p class="reminderModal__confirmCancelMessage">
          {{
            isDeleting
              ? ('delete_reminder_message' | ppTranslate)
              : ('unsaved_changes_message' | ppTranslate)
          }}
        </p>
      </div>

      <div *ngIf="!isDeleting" class="reminderModal__actionButtonsRow">
        <pp-button
          class="reminderModal__actionButton"
          data-test="cancelReminderButton"
          ppStyle="secondary"
          ppSize="wide"
          (ppAction)="discardChanges()"
          [ppWarning]="true"
          >{{ 'discard_changes' | ppTranslate }}</pp-button
        >
        <pp-button
          data-test="setReminderButton"
          class="reminderModal__actionButton"
          (ppAction)="toggleIsClosing()"
          ppStyle="primary"
          ppSize="wide"
          >{{ 'continue_editing' | ppTranslate }}</pp-button
        >
      </div>
      <div *ngIf="isDeleting" class="reminderModal__actionButtonsRow">
        <pp-button
          class="reminderModal__actionButton"
          data-test="cancelReminderButton"
          ppStyle="secondary"
          ppSize="wide"
          (ppAction)="toggleIsClosing()"
          >{{ 'cancel' | ppTranslate }}</pp-button
        >
        <pp-button
          data-test="setReminderButton"
          class="reminderModal__actionButton"
          (ppAction)="deleteConfirm()"
          ppStyle="primary"
          ppSize="wide"
          [ppWarning]="true"
          >{{ 'Delete' | ppTranslate }}</pp-button
        >
      </div>
    </div>
  </ng-template>
</pp-modal>
