import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';

import { userReducer } from './user.reducer';

import { AuthModule } from '../auth/auth.module';
import { LayoutModule } from '../layout/layout.module';

import { UserSettingsComponent } from './user-settings/user-settings.component';
import { userRoutes } from './user.routes';
import { UserService } from './user.service';

import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { CountdownModule } from '../../components/countdown/countdown.module';
import { DigitInputModule } from '../../components/input/digit-input/digit-input.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { SelectModule } from '../../components/input/select/select.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { FiltersModule } from '../filters/filters.module';
import { RemindersModule } from '../reminders/reminders.module';
import { SiteModule } from '../site/site.module';
import { EditNotificationsModalComponent } from './edit-notifications-modal/edit-notifications-modal.component';
import { SubscriptionsService } from './subscriptions.service';
import { UpdateEmailModalComponent } from './update-email-modal/update-email-modal.component';
import { UpdatePasswordModalComponent } from './update-password-modal/update-password-modal.component';
import { UpdateUserWithTwoFactorAuthenticationModalComponent } from './update-user-with-two-factor-authentication-modal/update-user-with-two-factor-authentication-modal.component';
import { UserSettingsAvatarComponent } from './user-settings/user-settings-avatar/user-settings-avatar.component';
import { UserSettingsPreferencesComponent } from './user-settings/user-settings-preferences/user-settings-preferences.component';
import { UserSettingsProfileTwoFactorAuthorizationComponent } from './user-settings/user-settings-profile/user-settings-profile-two-factor-authorization/user-settings-profile-two-factor-authorization.component';
import { UserSettingsProfileComponent } from './user-settings/user-settings-profile/user-settings-profile.component';
import { UserSettingsRemindersComponent } from './user-settings/user-settings-reminders/user-settings-reminders.component';
import { UserSettingsSitesComponent } from './user-settings/user-settings-sites/user-settings-sites.component';
import { UserSettingsTypeComponent } from './user-settings/user-settings-type/user-settings-type.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(userRoutes),
    StoreModule.forFeature('user', userReducer),
    LayoutModule,
    RemindersModule,
    AuthModule,
    SiteModule,
    FiltersModule,
    RadioModule,
    UploadModule,
    ModalModule,
    ButtonModule,
    SpinnerModule,
    SelectModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    SecureSrcModule,
    DigitInputModule,
    CountdownModule,
    ToggleModule,
  ],
  providers: [UserService, SubscriptionsService],
  declarations: [
    UserSettingsComponent,
    EditNotificationsModalComponent,
    UpdatePasswordModalComponent,
    UserSettingsAvatarComponent,
    UserSettingsProfileComponent,
    UserSettingsPreferencesComponent,
    UserSettingsTypeComponent,
    UserSettingsSitesComponent,
    UserSettingsProfileTwoFactorAuthorizationComponent,
    UpdateEmailModalComponent,
    UpdateUserWithTwoFactorAuthenticationModalComponent,
    UserSettingsRemindersComponent,
  ],
})
export class UserModule {}
