<pp-modal
  [ppNoFooter]="step === 'select' && !importingCustomFields"
  [ppScrollOnDefault]="true"
  [ppScrollX]="maxListDepthExceeded"
  [ppNoPadding]="true"
  [ppWidth]="653"
>
  <ng-container heading>
    {{ 'create_custom_field' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <section class="modalContent" [class.modalContent--hidden]="showingFormulaError">
      <pp-double-select
        *ngIf="step !== 'details'"
        [ppFirstOption]="'add_new_cf_capital' | ppTranslate"
        [ppSecondOption]="'import_cf_capital' | ppTranslate"
        (ppFirstChoice)="toggleImport(false)"
        (ppSecondChoice)="toggleImport(true)"
        [ppSelected]="importingCustomFields ? 1 : 0"
        class="hideMobile"
      ></pp-double-select>

      <div *ngIf="!importingCustomFields" class="newCFModal">
        <div class="newCFModal__header" *ngIf="step === 'select'">
          <h5 class="addCF__field-label modal__body-title">
            {{ 'choose_type' | ppTranslate }}
          </h5>

          <div class="addCF__grid-wrapper">
            <ng-container *ngFor="let customField of customFieldTypesData">
              <pp-custom-field-tile-small
                ppTooltip
                [ppImage]="customField.iconPath"
                [ppText]="customField.text"
                [ppDisabled]="
                  (customField.value === customFieldTypes.TIMELINE && timelineExists) ||
                  (!timelineEnabled && customField.value === customFieldTypes.TIMELINE)
                "
                [ppTitle]="
                  customField.value === customFieldTypes.TIMELINE && timelineExists
                    ? ('prompt_field_create_error_timeline_doubled' | ppTranslate)
                    : !timelineEnabled && customField.value === customFieldTypes.TIMELINE
                      ? ('enable_timeline' | ppTranslate)
                      : ''
                "
                (ppOnSelect)="setType(customField)"
              ></pp-custom-field-tile-small>
            </ng-container>

            <pp-custom-field-tile-small
              ppTooltip
              [ppImage]="EIconPath.ICON_INTEGRATION_VOLY_LOGO_24"
              [ppText]="'voly_integration' | ppTranslate"
              [ppTitle]="'voly_integration' | ppTranslate"
              (ppOnSelect)="navToInt()"
              *ngIf="integrationsVisible"
            ></pp-custom-field-tile-small>
          </div>
        </div>

        <pp-button
          ppStyle="primary"
          ppSize="small"
          *ngIf="
            step === 'details' &&
            field.type === customFieldTypes.LIST &&
            (user.isSuperUser || workspace.share.shareOption === EUserRole.OWNER)
          "
        >
          <label
            class="settingsNewSite__upload-button"
            [class.settingsNewSite__upload-button--disabled]="false"
            for="excel"
          >
            {{ 'create_list_from_excel' | ppTranslate }}
          </label>

          <pp-upload
            id="excel--update"
            [ppId]="'excel'"
            (ppUpload)="uploadExcel($event, customFieldTypes.LIST)"
            [ppDisabled]="false"
          >
          </pp-upload>
        </pp-button>

        <pp-create-multi-list-from-excel-button
          *ngIf="
            step === 'details' &&
            field.type === customFieldTypes.MULTI_LIST &&
            (user.isSuperUser || workspace.share.shareOption === EUserRole.OWNER)
          "
          (ppAction)="uploadExcel($event, customFieldTypes.MULTI_LIST)"
        ></pp-create-multi-list-from-excel-button>

        <pp-edit-custom-field-details
          [(ppCustomField)]="field"
          (ppGoBack)="back()"
          [ppWorkspaceId]="workspaceId"
          (ppListError)="updateListError($event)"
          *ngIf="step === 'details'"
        ></pp-edit-custom-field-details>
      </div>

      <div class="newCFModal" *ngIf="importingCustomFields">
        <pp-add-custom-field-modal-import
          [ppSelectedCustomFields]="selectedCustomFields"
          [ppProcessingImport]="processingImport"
          [ppTotalSelectedFields]="totalSelectedFields"
          [ppTimelineExists]="timelineExists"
          [ppTimelineEnabled]="timelineEnabled"
        ></pp-add-custom-field-modal-import>
      </div>
    </section>

    <pp-add-custom-field-modal-import-formula-error
      *ngIf="showingFormulaError"
    ></pp-add-custom-field-modal-import-formula-error>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      *ngIf="!importingCustomFields && step !== 'select'"
      [ppConfirmText]="'create' | ppTranslate"
      [ppProcess]="processing || processingImport"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="checkSaveButtonDisabled()"
    ></pp-modal-generic-footer>

    <pp-modal-generic-footer
      *ngIf="importingCustomFields && !showingFormulaError"
      [ppConfirmText]="'import' | ppTranslate"
      [ppProcess]="processing || processingImport"
      (ppCancel)="hideModal()"
      (ppConfirm)="importCustomFields()"
      [ppDisabled]="totalSelectedFields.number === 0"
    ></pp-modal-generic-footer>

    <pp-modal-generic-footer
      *ngIf="showingFormulaError"
      [ppConfirmText]="'ok' | ppTranslate"
      [ppHideCancel]="true"
      (ppConfirm)="toggleFormulaErrorModal(false)"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
