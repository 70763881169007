<div class="calendarDaySection">
  <div class="calendarReminders__time">
    {{ displayDate }}
  </div>

  <section class="calendarReminders">
    <ng-container *ngFor="let reminder of reminders">
      <pp-reminder-calendar-element
        [ppReminder]="reminder"
        [ppDailyView]="true"
      ></pp-reminder-calendar-element>
    </ng-container>
  </section>
</div>
