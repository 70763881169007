import { Component, Input } from '@angular/core';
import _ from 'lodash';
import { ModalService } from 'src/app/project/components/modal/modal.service';

import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { TReminder } from '../../../reminders/reminders.consts';
import { PointReminderModalComponent } from '../point-reminder-modal/point-reminder-modal.component';
import { TReminderModalData } from '../point-reminder-modal/point-reminder-modal.consts';
import { generateTimeText } from '../../utils/generate-time-text';

@Component({
  selector: 'pp-point-reminder',
  templateUrl: './point-reminder.component.html',
  styleUrls: ['./point-reminder.component.scss'],
})
export class PointReminderComponent {
  @Input() ppReminder: TReminder;
  reminderDueTime: string = '';
  reminderSnoozeTime: string = '';

  isOverdue: boolean = false;
  isDismissed: boolean = false;
  isSnoozed: boolean = false;

  refreshInterval: NodeJS.Timeout;
  EIconPath = EIconPath;
  private intervalStep: number = 60000;

  constructor(
    private modalService: ModalService,
    private preferencesService: PreferencesService,
  ) {}

  ngOnInit(): void {
    this.setDisplayData();

    this.refreshInterval = setInterval(() => {
      this.setDisplayData();
    }, this.intervalStep);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  viewReminder(): void {
    const modalData: TReminderModalData = {
      reminder: this.ppReminder,
    };

    this.modalService.setData(modalData);
    this.modalService.showModal(PointReminderModalComponent);
  }

  private isReminderOverdue(): boolean {
    const now = new Date().getTime();

    return now > this.ppReminder.timestampEpochMillis && !this.ppReminder.dismissed;
  }

  private isReminderSnoozed(): boolean {
    return (
      this.ppReminder.alertEpochMillis !== this.ppReminder.timestampEpochMillis &&
      !this.ppReminder.dismissed
    );
  }

  private setDisplayData(): void {
    const dateFormat = this.preferencesService.getPreferences().dateFormat;

    this.reminderDueTime = generateTimeText(this.ppReminder.timestampEpochMillis, dateFormat);
    this.isDismissed = this.ppReminder.dismissed;

    this.isOverdue = this.isReminderOverdue();
    this.isSnoozed = this.isReminderSnoozed();

    if (this.isSnoozed) {
      this.reminderSnoozeTime = generateTimeText(this.ppReminder.alertEpochMillis, dateFormat);
    }
  }
}
