import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStatus } from 'src/app/project/shared/enums/status.enum';

export function getReminderStatusIcon(reminderStatus: EStatus): EIconPath {
  switch (reminderStatus) {
    case EStatus.CANCELED:
      return EIconPath.ICON_BADGE_STATUS_CANCELED_14;
    case EStatus.CLOSED:
      return EIconPath.ICON_BADGE_STATUS_CLOSED_14;
    case EStatus.IN_PROGRESS:
      return EIconPath.ICON_BADGE_STATUS_IN_PROGRESS_14;
    case EStatus.ONHOLD:
      return EIconPath.ICON_BADGE_STATUS_ON_HOLD_14;
    case EStatus.OPEN:
      return EIconPath.ICON_BADGE_STATUS_OPEN_14;
    case EStatus.TO_REVIEW:
      return EIconPath.ICON_BADGE_STATUS_TO_REVIEW_14;
  }
}
