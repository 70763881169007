import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { TSync } from './sync.model';

import { SetActiveWorkspaceId } from '../../services/active/active.actions';
import { ActiveService } from '../../services/active/active.service';
import { NotificationsService } from '../notifications/notifications.service';
import { PreferencesService } from '../preferences/preferences-service/preferences.service';
import { UserService } from '../user/user.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { SetSynced, SetSyncing } from './sync.actions';

import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrenciesService } from '../custom-fields/currencies/currencies.service';
import { FleetService } from '../fleet-management/fleet-service/fleet.service';
import { RemindersService } from '../reminders/reminders.service';
import { SocketService } from '../sockets/socket.service';
import { TUserResponse } from '../user/response-models/user-response-model';
import { WhiteLabelService } from '../white-label/white-label.service';
import { EWorkspaces } from '../workspace/workspaces.enum';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  startTime: Date = null;

  constructor(
    private store: Store<{ sync: TSync }>,
    private router: Router,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private notificationsService: NotificationsService,
    private socketService: SocketService,
    private whiteLabelService: WhiteLabelService,
    private fleetService: FleetService,
    private currenciesService: CurrenciesService,
    private remindersService: RemindersService,
  ) {
    this.startTime = new Date();
  }

  firstLoad(workspaceId?: string): Observable<TUserResponse> {
    this.store.dispatch(new SetSynced(false));
    this.store.dispatch(new SetSyncing(true));

    return this.userService.fetchUser().pipe(
      tap(() => {
        const user = this.userService.getUser();
        const url = this.router.url;
        const isOverview = url.search(EWorkspaces.OVERVIEW) !== -1;
        const isTimeline = url.search(EWorkspaces.TIMELINE) !== -1;
        const isReminders = url.search(EWorkspaces.REMINDERS) !== -1;

        if (isOverview || isTimeline || isReminders) {
          this.store.dispatch(new SetActiveWorkspaceId(null));
        } else if (workspaceId) {
          this.store.dispatch(new SetActiveWorkspaceId(workspaceId));
        } else if (url.indexOf(this.activeService.getActiveWorkspaceId()) === -1) {
          this.store.dispatch(new SetActiveWorkspaceId(user.activeWorkspaceId));
        }

        forkJoin([
          this.workspaceService.generateBasicWorkspaces(),
          this.preferencesService.fetchPreferences(),
          this.notificationsService.fetchNotifications({
            refetch: true,
            offset: 0,
          }),
          this.fleetService.checkFleetAccess(),
          this.currenciesService.fetchCurrenciesList(),
          this.remindersService.fetchReminders(),
        ])
          .pipe(
            tap(() => {
              this.socketService.connect();

              if (user.activeWorkspaceId) {
                const workspace = this.workspaceService.findWorkspace(user.activeWorkspaceId);

                // User can be removed from their last visited workspace
                if (workspace) {
                  const accountId = workspace.accountId;
                  this.whiteLabelService.fetchWhiteLabel(accountId);
                }
              }

              this.store.dispatch(new SetSyncing(false));
              this.store.dispatch(new SetSynced(true));
            }),
          )
          .subscribe();
      }),
    );
  }

  getStartTime(): Date {
    return this.startTime;
  }
}
