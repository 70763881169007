<div class="cal-day-headers" role="row">
  <div
    class="cal-header"
    *ngFor="let day of ppDays; trackBy: trackByWeekDayHeaderDate"
    [class.cal-past]="day.isPast"
    [class.cal-today]="day.isToday"
    [class.cal-future]="day.isFuture"
    [class.cal-weekend]="day.isWeekend"
    [ngClass]="day.cssClass"
    mwlDroppable
    dragOverClass="cal-drag-over"
    tabindex="0"
    role="columnheader"
  >
    <pp-reminder-calendar-week-view-header-cell
      [ppDay]="day"
      [ppLocale]="ppLocale"
      (click)="openDay(day.date)"
    ></pp-reminder-calendar-week-view-header-cell>
  </div>
</div>
