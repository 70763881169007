import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { SetActivePointId, SetActiveWorkspaceId } from '../../../services/active/active.actions';
import { DeletePoint } from '../points.actions';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { PointAttachmentsService } from '../point-modal/point-attachments/point-attachments.service';

export type TPointFullModal = {
  visible: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class EditPointService {
  private fullPointModal: TPointFullModal = {
    visible: false,
  };

  private workspaceId: string = null;

  constructor(
    private store: Store,
    private router: Router,
    private pointAttachmentsService: PointAttachmentsService,
    private activeService: ActiveService,
    private modalService: ModalService,
  ) {}

  getModal(): TPointFullModal {
    return this.fullPointModal;
  }

  showModal(workspaceId: string, _id: string, isOverview: boolean = false): TPointFullModal {
    this.workspaceId = workspaceId;

    if (!this.fullPointModal.visible) {
      this.fullPointModal.visible = true;
    }

    if (!isOverview) {
      this.store.dispatch(new SetActiveWorkspaceId(workspaceId));
    } else {
      this.store.dispatch(new SetActiveWorkspaceId(null));
    }

    this.store.dispatch(new SetActivePointId(_id));

    return this.getModal();
  }

  hideModal(clear: boolean = false, error: boolean = false): Promise<void> {
    const isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;
    const isTimeline = this.router.url.search(EWorkspaces.TIMELINE) !== -1;
    const oldWorkspaceId = this.workspaceId;
    this.workspaceId = null;

    if ((oldWorkspaceId || isOverview) && (this.fullPointModal.visible || error)) {
      this.store.dispatch(new SetActivePointId(null));

      if (clear) {
        if (isOverview) {
          this.router.navigate(['/site/overview']);
        } else if (isTimeline) {
          this.router.navigate(['/site/timeline']);
        } else {
          this.router.navigate(['/site', oldWorkspaceId]);
        }
      }

      if (this.fullPointModal.visible) {
        this.fullPointModal.visible = false;
        this.pointAttachmentsService.cancelUploads();
        return this.modalService.hideModal();
      }
    }

    return new Promise(() => {});
  }

  hideNewModal(clear: boolean = false): TPointFullModal {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    if (this.fullPointModal.visible) {
      this.fullPointModal.visible = false;

      this.store.dispatch(
        new DeletePoint({
          workspaceId,
          _id: NEW_POINT_ID,
        }),
      );

      this.store.dispatch(new SetActiveWorkspaceId(workspaceId));
      this.store.dispatch(new SetActivePointId(null));
    }

    if (clear) {
      this.router.navigate(['/site', workspaceId]);
    }

    return this.getModal();
  }
}
