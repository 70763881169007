import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TReminder } from '../../reminders.consts';
import { TReminderEvent } from '../reminder-calendar-consts';
import dayjs from 'dayjs';

@Component({
  selector: 'pp-reminders-calendar-list',
  templateUrl: './reminders-calendar-list.component.html',
  styleUrl: './reminders-calendar-list.component.scss',
})
export class RemindersCalendarListComponent {
  @Input() ppEvents: TReminderEvent[];

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  reminders: TReminder[];
  oldReminders: TReminder[];
  newReminders: TReminder[];

  ngOnChanges(): void {
    this.setReminders();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const target = this.scrollContainer.nativeElement.querySelector(
        '.remindersCalendarList__textSeperator',
      );
      if (target) {
        const container = this.scrollContainer.nativeElement;
        container.scrollTop = target.offsetTop - container.clientHeight / 2;
      }
    }, 500);
  }

  setReminders(): void {
    this.reminders = this.ppEvents
      .map((event) => event.reminder)
      .sort((a, b) => a.timestampEpochMillis - b.timestampEpochMillis);

    const pastReminders = [];
    const todayOnwardReminders = [];

    this.reminders.forEach((reminder) => {
      if (dayjs(reminder.timestampEpochMillis).isBefore(dayjs(), 'day')) {
        pastReminders.push(reminder);
      } else {
        todayOnwardReminders.push(reminder);
      }
    });

    this.oldReminders = pastReminders;
    this.newReminders = todayOnwardReminders;
  }
}
