<section class="calendarReminders" [class.calendarReminders--first]="isStart">
  <ng-container *ngFor="let reminder of reminders; let index = index">
    <pp-reminder-calendar-element
      [ppReminder]="reminder"
      *ngIf="index < 2"
    ></pp-reminder-calendar-element>

    <button
      class="calendarEvent__more"
      *ngIf="index === 1 && reminders.length > 2"
      (click)="openDay(ppSegment.date)"
    >
      <span>+{{ 'x_more' | ppTranslate: { count: reminders.length - 2 } }} </span>
    </button>
  </ng-container>
</section>
