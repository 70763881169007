import { Component, Input } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TReminder, TReminderActionDTO } from '../../../reminders/reminders.consts';
import { RemindersService } from '../../../reminders/reminders.service';
import { ESnoozePeriod } from './reminder-snooze-button-consts';
import { ReminderSnoozeButtonService } from './reminder-snooze-button.service';
import { TReminderSnoozeDropdownData } from './reminder-snooze-dropdown/reminder-snooze-dropdown-consts';
import { ReminderSnoozeDropdownComponent } from './reminder-snooze-dropdown/reminder-snooze-dropdown.component';

@Component({
  selector: 'pp-reminder-snooze-button',
  templateUrl: './reminder-snooze-button.component.html',
  styleUrl: './reminder-snooze-button.component.scss',
})
export class ReminderSnoozeButtonComponent {
  @Input() ppReminder: TReminder;
  @Input() ppReminders: TReminder[];

  EIconPath = EIconPath;
  buttonId = 'reminderSnoozeButton';
  snoozePeriod: ESnoozePeriod = ESnoozePeriod.HOUR;
  snoozePeriodDisplay: string;
  dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private dropdownService: DropdownService,
    private remindersService: RemindersService,
    private reminderSnoozeButtonService: ReminderSnoozeButtonService,
  ) {}

  toogleSnoozeDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, ReminderSnoozeDropdownComponent, {
        callback: (option) => this.snooze(option),
        popper: {
          positionFixed: true,
          placement: 'top',
        },
        width: '119px',
      });
    }
  }

  private setDropdownData(): void {
    this.dropdownService.setData<TReminderSnoozeDropdownData>({
      multipleReminders: this.ppReminders.length > 1,
    });
  }

  snooze(options: { snoozePeriod: ESnoozePeriod; bulk: boolean }): void {
    const now = this.reminderSnoozeButtonService.approximateDateToFull15Minutes(new Date());

    switch (options.snoozePeriod) {
      case ESnoozePeriod.HOUR:
        now.setHours(now.getHours() + 1);
        break;
      case ESnoozePeriod.DAY:
        now.setDate(now.getDate() + 1);
        break;
      case ESnoozePeriod.WEEK:
        now.setDate(now.getDate() + 7);
        break;
    }

    const body: TReminderActionDTO = {
      reminderIds: options.bulk
        ? this.ppReminders.map((reminder) => reminder.reminderId)
        : [this.ppReminder.reminderId],
      alertEpochMillis: now.getTime(),
    };

    this.remindersService.snoozeReminders(body).subscribe();
  }
}
