<div
  data-test="timeSelect"
  class="reminderModal__select reminderModal__inputFields reminderModal__inputPadding"
>
  <select id="timeSelect" [(ngModel)]="time" (ngModelChange)="onTimeChange($event)">
    <option *ngFor="let option of options" [value]="option">{{ option }}</option>
  </select>

  <pp-icon
    class="reminderModal__selectIcon"
    ppClass="setSize14"
    ppColor="grey-500"
    [ppSrc]="EIconPath.ICON_FIELDS_TIME_14"
  ></pp-icon>
</div>
