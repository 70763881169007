import { Routes } from '@angular/router';
import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';
import { RemindersComponent } from './reminders/reminders.component';

export const reminderRoutes: Routes = [
  {
    path: 'reminders',
    component: RemindersComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },
];
