<div #sidePanelComponent *ngIf="user">
  <pp-side-panel-backdrop
    [ppSidePanel]="sidePanel"
    (ppClose)="closePanel()"
  ></pp-side-panel-backdrop>

  <div
    class="sidePanel__close-notifications"
    *ngIf="sidePanel.notificationsExpanded"
    (click)="toggleNotifications()"
    [class.hiddenMobile]="isMobile"
  >
    <img class="sidePanel__close-notifications-image" [src]="EIconPath.INTERACTION_CLOSE" />
  </div>

  <img
    *ngIf="!sidePanel.expanded && isMobile"
    [class.sidePanel__mobile-button--down]="actionBar"
    class="sidePanel__mobile-button"
    [src]="EIconPath.SIDE_PANEL_MENU"
    (click)="togglePanel()"
  />

  <div
    class="filterPanel sidebar__animation"
    [class.sidePanelBody--expanded]="
      sidePanel.expanded && (!isMobile || sidePanel.rightPanelExpanded)
    "
    [class.filterPanel--hidden]="sidePanel.hidden"
    [class.filterPanel--down]="actionBar"
    [class.sidePanelBody--collapsedMobile]="
      !sidePanel.expanded && isMobile && !sidePanel.rightPanelExpanded
    "
  >
    <div class="sidePanel__left">
      <pp-side-panel-back *ngIf="showBackArrow" [ppDisabled]="disabled"></pp-side-panel-back>
      <pp-side-panel-logo *ngIf="!showBackArrow" [ppMobile]="isMobile"></pp-side-panel-logo>

      <div class="sidePanel__mainContent">
        <pp-side-panel-dashboard
          [ppRouterPath]="routerPath"
          [ppActiveWorkspaceId]="user.activeWorkspaceId"
          [ppDisabled]="disabled"
        ></pp-side-panel-dashboard>

        <pp-side-panel-overview
          [ppTimelineEnabled]="timelineEnabled"
          [ppRouterPath]="routerPath"
          [ppDisabled]="disabled"
        ></pp-side-panel-overview>

        <pp-side-panel-timeline
          [ppRouterPath]="routerPath"
          *ngIf="timelineEnabled"
          [ppDisabled]="disabled"
        ></pp-side-panel-timeline>

        <pp-side-panel-sites [ppIsMobile]="isMobile" [ppDisabled]="disabled"></pp-side-panel-sites>

        <pp-side-panel-fleet-management
          *ngIf="fleetManagementEnabled"
          [ppRouterPath]="routerPath"
          [ppDisabled]="disabled"
        ></pp-side-panel-fleet-management>
      </div>

      <div class="sidePanel__endContent">
        <pp-side-panel-reminders
          [ppRouterPath]="routerPath"
          [ppActiveWorkspaceId]="user.activeWorkspaceId"
          [ppDisabled]="disabled"
        ></pp-side-panel-reminders>

        <pp-side-panel-notifications
          [ppUser]="user"
          [ppDisabled]="disabled"
        ></pp-side-panel-notifications>

        <pp-side-panel-user [ppUser]="user" [ppDisabled]="disabled"></pp-side-panel-user>
      </div>
    </div>

    <pp-side-panel-sites-list
      *ngIf="(!isMobile && sidePanel.expanded) || (isMobile && sidePanel.rightPanelExpanded)"
    ></pp-side-panel-sites-list>
  </div>

  <pp-notifications
    class="notificationsPanel sidebar__animation"
    [ngClass]="sidePanel.expanded ? 'notificationsPanel--right' : ''"
    [class.notificationsPanel--collapsed]="!sidePanel.notificationsExpanded"
    [ppExpanded]="sidePanel.notificationsExpanded"
  ></pp-notifications>
</div>
