import { TFilters, TSitesFilter } from '../site-filter.model';
import { isSitesDefault } from './is-sites-default';

export function clearSites(
  filters: TFilters,
  applied?: boolean,
): { sites: TSitesFilter[]; default: boolean } {
  filters.sites.forEach((account) => {
    account.value = account.default;
    account.appliedValue = account.default;

    account.workspaces.forEach((site) => {
      site.value = site.default;
      site.appliedValue = site.default;
    });
  });

  filters.sitesDefault = isSitesDefault(filters);

  return {
    sites: filters.sites,
    default: filters.sitesDefault,
  };
}
