import { Component, Input, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TReminder } from '../../../reminders/reminders.consts';
import { getReminderPriorityData } from '../../../reminders/utils/get-reminder-priority-data';
import { getReminderStatusIcon } from '../../../reminders/utils/get-reminder-status-icon';
import { SiteTableEventsService } from '../../../site/site-table/site-table-events.service';

@Component({
  selector: 'pp-reminder-point-reference',
  templateUrl: './reminder-point-reference.component.html',
  styleUrl: './reminder-point-reference.component.scss',
})
export class ReminderPointReferenceComponent implements OnChanges {
  @Input() ppReminder: TReminder;
  @Input() ppBrightMode: boolean;

  priorityClass: string;
  priorityText: string;
  statusIcon: EIconPath;

  constructor(
    private translationPipe: TranslationPipe,
    private siteTableEventsService: SiteTableEventsService,
  ) {
    this.statusIcon = EIconPath.ICON_BADGE_STATUS_OPEN_14;
    this.priorityClass = 'reminderPointReference__priority--high';
    this.priorityText = this.translationPipe.transform('high');
  }

  ngOnChanges(): void {
    this.setStatusIcon();
    this.setPriorityData();
  }

  openPoint(event: Event): void {
    event.stopPropagation();

    this.siteTableEventsService.openPointFromNotification(
      this.ppReminder.workspaceId,
      this.ppReminder.pointId,
    );
  }

  private setStatusIcon(): void {
    this.statusIcon = getReminderStatusIcon(this.ppReminder.pointStatus);
  }

  private setPriorityData(): void {
    const priorityData = getReminderPriorityData(this.ppReminder.pointPriority);
    this.priorityClass = priorityData.priorityClass;
    this.priorityText = priorityData.priorityText;
  }
}
