<div class="reminder">
  <div class="reminderContent">
    <div
      class="reminderAlert"
      [class.reminderAlert__highlight]="ppHighlighted"
      (click)="selectReminder()"
    >
      <div class="reminderAlert__content">
        <div class="reminderTitle">
          <span
            (click)="openReminderModal()"
            class="reminderTitle__inner"
            ppTooltip
            [ppTitle]="ppReminder.name"
            [ppTrimTooltip]="true"
            >{{ ppReminder.name }}</span
          >
        </div>
        <div class="reminderDescription">
          <span class="reminderDescription__inner"> {{ ppReminder.description }}</span>
        </div>
        <pp-reminder-point-reference
          *ngIf="ppReminder.pointId"
          [ppReminder]="ppReminder"
        ></pp-reminder-point-reference>
      </div>

      <pp-reminder-timestamp [ppReminder]="ppReminder"></pp-reminder-timestamp>
    </div>
  </div>
</div>
