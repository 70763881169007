import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  TReminder,
  TReminderActionDTO,
  TReminderDTO,
  TReminderUpdateDTO,
} from '../../../modules/reminders/reminders.consts';
import {
  API_reminder,
  API_reminder_dismiss,
  API_reminder_snooze,
  API_reminders,
  API_reminders_filtered,
  API_reminders_point,
} from './reminders-paths';

@Injectable({
  providedIn: 'root',
})
export class RemindersApiProviderService {
  constructor(private apiService: ApiService) {}

  createReminder(body: TReminderDTO): Observable<TReminder> {
    const url = API_reminders();

    return this.apiService.post<TReminder>(url, body);
  }

  getReminders(): Observable<TReminder[]> {
    const url = API_reminders();

    return this.apiService.get<TReminder[]>(url);
  }

  getFilteredReminders(timestampUntil: number): Observable<TReminder[]> {
    const url = API_reminders_filtered(timestampUntil);

    return this.apiService.get<TReminder[]>(url);
  }

  getRemindersForPoint(pointId: string): Observable<TReminder[]> {
    const url = API_reminders_point(pointId);

    return this.apiService.get<TReminder[]>(url);
  }

  editReminder(reminderId: string, body: TReminderUpdateDTO): Observable<TReminder> {
    const url = API_reminder(reminderId);

    return this.apiService.put<TReminder>(url, body);
  }

  deleteReminder(reminderId: string): Observable<null> {
    const url = API_reminder(reminderId);

    return this.apiService.delete<null>(url);
  }

  snoozeReminders(body: TReminderActionDTO): Observable<TReminder[]> {
    const url = API_reminder_snooze();

    return this.apiService.post<TReminder[]>(url, body);
  }

  dismissReminders(reminders: TReminderActionDTO): Observable<TReminder[]> {
    const url = API_reminder_dismiss();

    return this.apiService.post<TReminder[]>(url, reminders);
  }
}
