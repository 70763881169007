import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { IconModule } from '../../components/icon/icon-module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { reminderRoutes } from '../reminders/reminders.routes';
import { ReminderAlertComponent } from './reminder-alert/reminder-alert.component';
import { ReminderDismissButtonComponent } from './reminder-alert/reminder-dismiss-button/reminder-dismiss-button.component';
import { ReminderDismissDropdownComponent } from './reminder-alert/reminder-dismiss-button/reminder-dismiss-dropdown/reminder-dismiss-dropdown.component';
import { ReminderPointReferenceComponent } from './reminder-alert/reminder-point-reference/reminder-point-reference.component';
import { ReminderSnoozeButtonComponent } from './reminder-alert/reminder-snooze-button/reminder-snooze-button.component';
import { ReminderSnoozeDropdownComponent } from './reminder-alert/reminder-snooze-button/reminder-snooze-dropdown/reminder-snooze-dropdown.component';
import { ReminderTimestampComponent } from './reminder-alert/reminder-timestamp/reminder-timestamp.component';
import { ReminderPopupComponent } from './reminder-popup/reminder-popup.component';

// New module so we avoid circular dependencies in layout -> reminders -> layout modules
@NgModule({
  declarations: [
    ReminderPopupComponent,
    ReminderAlertComponent,
    ReminderDismissButtonComponent,
    ReminderSnoozeButtonComponent,
    ReminderTimestampComponent,
    ReminderPointReferenceComponent,
    ReminderSnoozeDropdownComponent,
    ReminderDismissDropdownComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(reminderRoutes),
    FormsModule,
    SaveIndicatorModule,
    DatepickerModule,
    SaveIndicatorModule,
    PipesModule,
    ButtonModule,
    TooltipsModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    ModalModule,
    IconModule,
  ],
  exports: [ReminderPopupComponent, ReminderPointReferenceComponent],
})
export class ReminderPopupModule {}
