import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterDuration } from '../../models/advanced-filter.model';

export function filterDurationAdvanced(point: TPoint, filter: TAdvancedFilterDuration): boolean {
  const filterValue = filter.value ? filter.value : 0;

  const pointValue = point.customFieldsMap?.[filter.id]?.value;
  const fieldValue = pointValue ? +pointValue : 0;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return +fieldValue === +filterValue;
    case EAdvancedFilterOptions.IS_NOT:
      return +fieldValue !== +filterValue;
    case EAdvancedFilterOptions.IS_GREATER_THAN:
      return +fieldValue > +filterValue;
    case EAdvancedFilterOptions.IS_LESS_THAN:
      return +fieldValue < +filterValue;
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue;
  }
}
