import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '../modules/layout/layout.module';
import { SupportedBrowsersModule } from '../modules/supported-browsers/supported-browsers.module';
import { CommonComponentsComponent } from './common-components/common-components.component';

@NgModule({
  declarations: [CommonComponentsComponent],
  imports: [CommonModule, LayoutModule, SupportedBrowsersModule],
  exports: [CommonComponentsComponent],
})
export class CommonComponentsModule {}
