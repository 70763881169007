<pp-side-panel-menu-button
  [ppText]="'calendar' | ppTranslate"
  [ppDataTarget]="'Reminder icon'"
  [ppDataTest]="'remindersMenuItem'"
  [ppShowIndicator]="false"
  [ppIconSrc]="EIconPath.ICON_LEFT_MENU_CALENDAR_24"
  [ppActive]="ppRouterPath.startsWith('/reminders')"
  [ppDisabled]="ppDisabled"
  (ppAction)="goToReminders()"
></pp-side-panel-menu-button>
