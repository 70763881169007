import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PreviousPageService {
  private previousUrlStack: string[] = [];

  setPreviousFleetUrl(url: string): void {
    this.previousUrlStack.push(url);
  }

  setPreviousRemindersUrl(url: string): void {
    this.previousUrlStack.push(url);
  }

  setPreviousSiteUrl(url: string): void {
    if (this.previousUrlStack.length === 0) {
      this.previousUrlStack.push(url);
      return;
    }

    if (this.previousUrlStack[0].startsWith('/fleet-management')) {
      this.previousUrlStack = [this.previousUrlStack[0], url];
    } else {
      this.previousUrlStack = [url];
    }
  }

  getPreviousUrl(): string {
    return this.previousUrlStack[this.previousUrlStack.length - 1] || '';
  }

  navToPreviousUrl(): string {
    return this.previousUrlStack.pop();
  }

  clearUrlStack(): void {
    this.previousUrlStack = [];
  }
}
