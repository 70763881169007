<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout>
  <ng-container *ngIf="ppPasswordResetUser">
    <section class="authBox">
      <header>
        <h4 class="authBox__title">{{ 'reset_password' | ppTranslate }}</h4>
        <p class="authBox__copy">{{ 'create_new_password' | ppTranslate }}</p>
      </header>

      <form class="authBox__inputs-container" name="resetForm">
        <ng-container *ngIf="ppPasswordResetUser.email">
          <div class="authBox__input-wrapper--no-hover">
            <pp-icon
              [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
              alt="outline of a letter"
              ppColor="grey-600"
              ppClass="setSize18"
            ></pp-icon>

            <input
              type="text"
              class="authBox__input--disabled"
              value="{{ ppPasswordResetUser.email }}"
              readonly
              autocomplete="on"
            />
          </div>
        </ng-container>

        <div class="authBox__input-wrapper">
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
            alt="outline of a lock"
            ppColor="grey-600"
            ppClass="setSize18"
          ></pp-icon>
          <input
            class="authBox__input"
            [type]="passwordInputType"
            [placeholder]="'new_password' | ppTranslate | titlecase"
            [(ngModel)]="ppPasswordResetUser.passwordHash"
            (ngModelChange)="updateResetUser()"
            name="password"
            autocomplete="new-password"
            (keydown.enter)="blurInput($event)"
            (keyup)="checkPassword()"
            required
          />
          <pp-password-visibility
            (ppVisibilityChange)="passwordInputType = $event"
          ></pp-password-visibility>
        </div>

        <div>
          <div class="authBox__input-wrapper">
            <label class="sr-only" for="new-signup-password">
              {{ 'confirm_new_password' | ppTranslate }}
            </label>

            <pp-icon
              [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
              alt="outline of a lock"
              ppColor="grey-600"
              ppClass="setSize18"
            ></pp-icon>
            <input
              class="authBox__input"
              [type]="passwordInputType"
              [placeholder]="'confirm_new_password' | ppTranslate"
              [(ngModel)]="repeatedPassword"
              name="password"
              (keydown.enter)="blurInput($event)"
              (keyup)="checkPassword()"
            />

            <pp-icon
              *ngIf="
                ppPasswordResetUser?.passwordHash !== repeatedPassword &&
                repeatedPassword.length > 0
              "
              [ppSrc]="EIconPath.ICON_MISC_INFO"
              alt="outline of a lock"
              ppColor="grey-600"
              ppClass="setSize18"
              ppTooltip
              [ppTitle]="'passwords_dont_match' | ppTranslate"
              [ppMobileTooltip]="true"
            ></pp-icon>
          </div>

          <pp-password-validation
            *ngIf="isNewPasswordInputDirty"
            (ppValidate)="validatePassword($event)"
            [ppPassword]="ppPasswordResetUser?.passwordHash"
          ></pp-password-validation>
        </div>

        <div class="passwordUpdate__row">
          <pp-checkbox
            [ppChecked]="areTermsAccepted"
            ppType="solid"
            (ppOnValueChange)="toggleAcceptedTerms()"
          ></pp-checkbox>
          <label class="authBox__label">
            {{ 'accept' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/terms-and-conditions"
              target="_blank"
            >
              {{ 'terms_and_conditions' | ppTranslate }}
            </a>
            {{ 'and' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/privacy-policy"
              target="_blank"
            >
              {{ 'privacy_policy' | ppTranslate }}
            </a>
          </label>
        </div>

        <footer>
          <pp-button
            ppStyle="primary"
            ppId="updatePasswordSignInBtn"
            [ppProcess]="ppProcessing"
            [ppDisabled]="checkConfirmButtonDisabled()"
            (ppAction)="updatePassword()"
          >
            {{ 'sign_in' | ppTranslate }}
          </pp-button>

          <a class="authBox__return-link" routerLink="/login">
            <pp-icon
              [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
              ppColor="grey-600"
              alt="black arrow pointing back"
            ></pp-icon>
            {{ 'return_to_log_in' | ppTranslate }}
          </a>
        </footer>
      </form>
    </section>
  </ng-container>
</pp-not-authorized-layout>
