import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EKeyboardKeyCode } from 'src/app/core/helpers/keyboard-codes';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { PointModalHeaderService } from '../point-modal-header/point-modal-header.service';
import { TPoint } from '../points.model';
import { PointDocumentsService } from './point-attachments/point-documents/point-documents.service';
import { PointFieldsService } from './point-fields/point-fields.service';

@Injectable({
  providedIn: 'root',
})
export class PointArrowRedirectService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dropdownService: DropdownService,
    private pointDocumentsService: PointDocumentsService,
    private pointFieldsService: PointFieldsService,
    private router: Router,
    private pointModalHeaderService: PointModalHeaderService,
  ) {}

  preventArrowEventIfNeeded(event: KeyboardEvent): void {
    if (!this.shouldPrevent()) {
      return;
    }
    event.preventDefault();
  }

  tryRedirectPointOnKeyup({
    event,
    attachmentUploading,
    point,
    id,
    isTimeline,
    isOverview,
    isFull,
  }: {
    event: KeyboardEvent;
    attachmentUploading: boolean;
    point: TPoint;
    id: string;
    isTimeline: boolean;
    isOverview: boolean;
    isFull: boolean;
  }): void {
    if (!this.shouldPrevent()) {
      return;
    }

    if (event.key === EKeyboardKeyCode.ArrowDown) {
      event.preventDefault();

      this.nextPoint({
        attachmentUploading: attachmentUploading,
        point: point,
        id: id,
        isTimeline: isTimeline,
        isOverview: isOverview,
        isFull,
      });
    }

    if (event.key === EKeyboardKeyCode.ArrowUp) {
      event.preventDefault();

      this.prevPoint({
        attachmentUploading: attachmentUploading,
        point: point,
        id: id,
        isTimeline: isTimeline,
        isOverview: isOverview,
        isFull,
      });
    }
  }

  prevPoint({
    attachmentUploading,
    point,
    id,
    isTimeline,
    isOverview,
    isFull,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    id: string;
    isTimeline: boolean;
    isOverview: boolean;
    isFull: boolean;
  }): void {
    this.pointModalHeaderService.prevPoint({
      attachmentUploading: attachmentUploading,
      point: point,
      _id: id,
      isTimeline: isTimeline,
      isOverview: isOverview,
      isFull: isFull,
    });
  }

  nextPoint({
    attachmentUploading,
    point,
    id,
    isTimeline,
    isOverview,
    isFull,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    id: string;
    isTimeline: boolean;
    isOverview: boolean;
    isFull: boolean;
  }): void {
    this.pointModalHeaderService.nextPoint({
      attachmentUploading: attachmentUploading,
      point: point,
      _id: id,
      isTimeline: isTimeline,
      isOverview: isOverview,
      isFull: isFull,
    });
  }

  private shouldPrevent(): boolean {
    const dropdownOpened = this.dropdownService.getDropdown().visible;
    const documentOpened = this.pointDocumentsService.getDocumentOpened();
    const isEditingQuill = this.pointFieldsService.getIsEditingQuill();

    if (
      this.document.activeElement.classList.contains('ql-editor') ||
      this.document.activeElement.hasAttribute('preventPointSwitching') ||
      dropdownOpened ||
      this.router.url.includes('gallery') ||
      this.router.url.includes('reminders') ||
      documentOpened ||
      isEditingQuill.editing
    ) {
      return false;
    }

    return true;
  }
}
