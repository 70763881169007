import { TReminder } from '../../reminders/reminders.consts';
import { TFilters } from '../site-filter.model';

export function filterReminders(filters: TFilters, reminders: TReminder[]): TReminder[] {
  const startDate = filters.reminders?.startDate;
  const endDate = filters.reminders?.endDate;

  if (!filters.reminders?.startDate && !filters.reminders?.endDate) {
    return reminders;
  }

  const filteredReminders = reminders.filter((reminder) => {
    const reminderDate = reminder.timestampEpochMillis;
    const afterStartDate = !startDate || reminderDate >= startDate;
    const beforeEndDate = !endDate || reminderDate <= endDate;

    return afterStartDate && beforeEndDate;
  });

  return filteredReminders;
}
