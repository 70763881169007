import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointReminderModalComponent } from '../point-reminder-modal/point-reminder-modal.component';

@Component({
  selector: 'pp-point-reminders-header',
  templateUrl: './point-reminders-header.component.html',
  styleUrls: ['./point-reminders-header.component.scss'],
})
export class PointRemindersHeaderComponent {
  @Input() ppPointId: string = '';
  @Input() ppHide: boolean = false;
  EIconPath = EIconPath;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) {}

  back(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  newReminder(): void {
    this.modalService.setData({ pointId: this.ppPointId });
    this.modalService.showModal(PointReminderModalComponent);
  }
}
