import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import {
  TAllFilters,
  TCustomFieldFilter,
  TFilters,
  TPriorityFilter,
  TRemindersFilter,
  TSitesFilter,
  TStatusFilter,
  TTagsFilter,
} from '../site-filter.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from '../../../services/active/active.service';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';

import { EStore } from '../../../shared/enums/store.enum';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import { SearchKeywordService } from '../search-keyword.service';
import { SelectableFiltersService } from '../update-selectable-filters/selectable-filters.service';
import { clearAssignees } from './clear-assignees';
import { clearCreatedBy } from './clear-created-by';
import { clearCustomFieldFilter } from './clear-custom-fields-filter';
import { clearDateCreated } from './clear-date-created';
import { clearDateUpdated } from './clear-date-updated';
import { clearExcludedTags } from './clear-excluded-tags';
import { clearFilterReminders } from './clear-filter-reminders';
import { clearFlaggedPoints } from './clear-flagged-points';
import { clearKeyword } from './clear-keyword';
import { clearPointsWithLocation } from './clear-points-with-location';
import { clearPointsWithoutLocation } from './clear-points-without-location';
import { clearPriority } from './clear-priority';
import { clearSites } from './clear-sites';
import { clearStatus } from './clear-status';
import { clearTags } from './clear-tags';
import { clearUnflaggedPoints } from './clear-unflagged-points';

@Injectable({
  providedIn: 'root',
})
export class ClearFilterService {
  private readonly destroy$ = new Subject<void>();
  private filters: TFilters;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private activeService: ActiveService,
    private updateSelectableFiltersService: SelectableFiltersService,
    private searchKeywordService: SearchKeywordService,
    private customTableService: CustomTableService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });
  }

  clearKeyword(): void {
    const table = this.customTableService.getTable();

    clearKeyword(this.filters);

    if (table) {
      table.setKeyword('');
    }

    this.searchKeywordService.setKeyword('', []);
  }

  clearSites(applied?: boolean): { sites: TSitesFilter[]; default: boolean } {
    return clearSites(this.filters, applied);
  }

  clearPriority(applied?: boolean): TPriorityFilter[] {
    return clearPriority(this.filters, applied);
  }

  clearPointsWithoutLocation(): boolean {
    return clearPointsWithoutLocation(this.filters);
  }

  clearFlaggedPoints(): boolean {
    return clearFlaggedPoints(this.filters);
  }

  clearUnflaggedPoints(): boolean {
    return clearUnflaggedPoints(this.filters);
  }

  clearPointsWithLocation(): boolean {
    return clearPointsWithLocation(this.filters);
  }

  clearReminders(): TRemindersFilter {
    return clearFilterReminders(this.filters);
  }

  clearStatus(applied?: boolean): TStatusFilter[] {
    return clearStatus(this.filters, applied);
  }

  clearTags(): TTagsFilter {
    clearTags(this.filters);
    this.updateSelectableFiltersService.updateSelectableTags();

    return this.filters.tags;
  }

  clearExcludedTags(): TTagsFilter {
    clearExcludedTags(this.filters);
    this.updateSelectableFiltersService.updateSelectableExcludedTags();

    return this.filters.excludedTags;
  }

  clearCreatedBy(): void {
    clearCreatedBy(this.filters);
  }

  clearAssignees(): { value: string[]; appliedValue: string[]; applied: boolean } {
    clearAssignees(this.filters);

    return this.filters.assignees;
  }

  clearDateCreated(applied?: boolean): void {
    clearDateCreated(this.filters, applied);
  }

  clearDateUpdated(applied?: boolean): void {
    clearDateUpdated(this.filters, applied);
  }

  clearCustomFieldFilter(applied: boolean, field: TCustomFieldFilter): TCustomFieldFilter[] {
    const customFields = this.customFieldsService.getCustomFields();

    return clearCustomFieldFilter(applied, field, customFields, this.filters);
  }

  removeSites(): void {
    this.filters.sites = [];
  }

  clearFilters(applied: boolean = false, removeSites: boolean = false): TFilters {
    const activeWorkspace = this.workspaceService.getActiveWorkspace();
    const customFields = this.customFieldsService.getCustomFields();

    this.clearPointsWithoutLocation();
    this.clearPointsWithLocation();
    this.clearKeyword();
    this.clearPriority(applied);
    this.clearStatus(applied);
    this.clearTags();
    this.clearDateCreated(applied);
    this.clearDateUpdated(applied);
    this.clearCreatedBy();
    this.clearAssignees();
    this.clearExcludedTags();
    this.clearReminders();
    this.clearFlaggedPoints();
    this.clearUnflaggedPoints();

    if (Object.entries(customFields).length !== 0) {
      const fields = [...this.filters.customFields];

      fields.forEach((field) => {
        this.clearCustomFieldFilter(applied, field);
      });
    }

    if (!removeSites && !activeWorkspace) {
      const clearSites = this.clearSites();

      this.filters.sites = clearSites.sites;
      this.filters.sitesDefault = clearSites.default;
    } else {
      this.removeSites();
      this.filters.sitesDefault = null;
    }

    return this.filters;
  }
}
