<div
  (ppOnRendered)="onRendered()"
  class="modal__backdrop"
  [style.z-index]="zIndex"
  [class.modal__backdrop--visible]="modal.visible"
  [class.modal__backdrop--blur]="modal.blur"
  (mousedown)="hideFromClickOutside()"
>
  <div
    class="modal__box"
    [style.width]="ppWidth + 'px !important'"
    [ngClass]="{ 'modal__box--visible': modal.visible, 'modal__box--wide': ppWide }"
    [class.modal__box--thin]="ppThin"
    (click)="(null); $event.stopPropagation()"
    (mousedown)="$event.stopPropagation()"
  >
    <header class="modal__header" *ngIf="!ppNoHeader">
      <h4 class="modal-title">
        <ng-content select="[heading]"></ng-content>
      </h4>

      <h3>
        <ng-content select="[subheading]"></ng-content>
      </h3>

      <div *ngIf="!ppHideClose" class="modal__close" (mouseup)="hide(false)">
        <img
          data-m-target="Modal Close Button"
          [src]="EIconPath.INTERACTION_CLOSE_WHITE"
          class="modal__close--img"
        />
      </div>
    </header>

    <section>
      <ng-content select="[tabs]"></ng-content>
    </section>

    <section
      #modalBody
      (ppOnRendered)="onModalBaseRendered($event)"
      class="modal__body"
      [ngClass]="{
        'no-padding': ppNoPadding,
        'modal__body--scrollable': needsScroll,
        'modal__body--scrollX': ppScrollX,
      }"
    >
      <ng-content select="[body]"></ng-content>
    </section>

    <footer class="modal__footer" *ngIf="!ppNoFooter">
      <div class="actionBar--shadow" *ngIf="!scrolledToBottom && needsScroll && ppFooterShadow">
        &nbsp;
      </div>

      <ng-content select="[footer]"></ng-content>
    </footer>
  </div>
</div>
