import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterList } from '../../models/advanced-filter.model';

export function filterListAdvanced(point: TPoint, filter: TAdvancedFilterList): boolean {
  const filterValue = filter.value
    ? filter.value
    : {
        path: null,
        label: null,
        id: null,
      };

  let fieldValue = point.customFieldsMap?.[filter.id]?.value;

  if (!fieldValue) {
    fieldValue = '';
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return fieldValue === filterValue.path;
    case EAdvancedFilterOptions.IS_NOT:
      return fieldValue !== filterValue.path;
    case EAdvancedFilterOptions.IS_A_PART_OF: {
      return fieldValue.startsWith(filterValue.path);
    }
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue;
  }
}
