import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EInstance } from 'src/app/project/shared/enums/instances';
import { EStore } from '../../../../../shared/enums/store.enum';
import { TUser } from '../../../../user/user.model';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';
import { SidePanelUtilsService } from '../side-panel-utils.service';

@Component({
  selector: 'pp-side-panel-overview',
  templateUrl: './side-panel-overview.component.html',
})
export class SidePanelOverviewComponent implements OnInit, OnDestroy {
  @Input() ppTimelineEnabled: boolean;
  @Input() ppRouterPath: string;
  @Input() ppDisabled: boolean;

  EIconPath = EIconPath;
  user$: Observable<TUser>;
  private readonly destroy$ = new Subject<void>();

  user: TUser;
  overviewBlocked: boolean;
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  constructor(
    @Inject('HOSTNAME') private host: string,
    private store: Store<{
      user: TUser;
    }>,
    private router: Router,
    private sidePanelUtilsService: SidePanelUtilsService,
    private sidePanelService: SidePanelService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.overviewBlocked = this.user.isSuperUser && this.host !== EInstance.ASM;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goToOverview(): void {
    if (this.user.activeWorkspaceId && !this.overviewBlocked) {
      if (!this.router.url.startsWith('/site/overview')) {
        this.sidePanel.notificationsExpanded = false;

        this.sidePanelUtilsService.collapsePanel();
        this.sidePanelService.goToOverview(false);
      }

      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_OVERVIEW, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });
    }
  }
}
