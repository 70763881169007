<div class="base">
  <pp-common-components [ppFleetLoaded]="fleetExists"></pp-common-components>

  <div
    #fleetManagement
    class="fleetManagement"
    [class.fleetManagement--full-width]="!sidePanel.expandedInitially"
    [class.fleetManagement--down]="ui.actionBar"
    [class.fleetManagement--topBarSpacing]="applySpacingFromTopBar"
  >
    <router-outlet></router-outlet>
  </div>
</div>
