<div
  class="reminderPointReference"
  (click)="openPoint($event)"
  [class.reminderPointReference--bright]="ppBrightMode"
>
  <div class="reminderPointReference__icons">
    <div class="reminderPointReference__priority" [ngClass]="priorityClass">{{ priorityText }}</div>
    <div class="reminderPointReference__status">
      <pp-icon
        [ppSrc]="statusIcon"
        ppClass="setSize14"
        [ppColor]="ppBrightMode ? 'grey-500' : 'white'"
      ></pp-icon>
    </div>

    <span
      class="reminderPointReference__text"
      ppTooltip
      [ppTitle]="ppReminder.pointTitle"
      [ppTrimTooltip]="true"
      >{{ ppReminder.pointTitle }}</span
    >
  </div>
</div>
