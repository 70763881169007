import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterText } from '../../models/advanced-filter.model';

export function filterTextAdvanced(point: TPoint, filter: TAdvancedFilterText): boolean {
  const filterValue = filter.value ? filter.value : '';

  const fieldValue = point.customFieldsMap?.[filter.id]?.value;
  const valueToFilter = fieldValue ? fieldValue : '';

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return valueToFilter.localeCompare(filterValue, undefined, { sensitivity: 'base' }) === 0;
    case EAdvancedFilterOptions.IS_NOT:
      return valueToFilter.localeCompare(filterValue, undefined, { sensitivity: 'base' }) !== 0;
    case EAdvancedFilterOptions.CONTAINS:
      return valueToFilter.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
    case EAdvancedFilterOptions.DOES_NOT_CONTAIN:
      return valueToFilter.toLowerCase().indexOf(filterValue.toLowerCase()) === -1;
    case EAdvancedFilterOptions.IS_EMPTY:
      return !valueToFilter;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!valueToFilter;
  }
}
