import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TPreferences } from '../../preferences/preferences.model';
import { TReminder } from '../../reminders/reminders.consts';
import { RemindersService } from '../../reminders/reminders.service';

@Component({
  selector: 'pp-reminder-popup',
  templateUrl: './reminder-popup.component.html',
  styleUrl: './reminder-popup.component.scss',
})
export class ReminderPopupComponent implements OnDestroy {
  remindersList: TReminder[] = [];
  highlightedReminder: TReminder;
  remindersPopupEnabled: boolean;

  private destroy$ = new Subject<void>();
  private preferences$: Observable<TPreferences>;

  constructor(
    private remindersService: RemindersService,
    private store: Store<{
      preferences: TPreferences;
    }>,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.remindersPopupEnabled = preferences.reminderSettings.alerts;
    });

    this.setReminders(this.remindersService.getReminders());

    this.remindersService.remindersChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reminders) => {
        this.setReminders(reminders);
      });
  }

  private setReminders(reminders: TReminder[]) {
    this.remindersList = reminders
      .filter((reminder) => {
        if (reminder.dismissed) {
          return false;
        }

        const now = new Date();

        return reminder.alertEpochMillis < now.getTime();
      })
      .sort((a, b) => b.alertEpochMillis - a.alertEpochMillis)
      .reverse();

    this.highlightedReminder = this.remindersList[this.remindersList.length - 1];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  remindersTrackBy(index: number, reminder: TReminder): string {
    return reminder.reminderId;
  }

  selectReminder(reminder: TReminder): void {
    this.highlightedReminder = reminder;
  }
}
