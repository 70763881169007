import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-common-components',
  templateUrl: './common-components.component.html',
  styleUrl: './common-components.component.scss',
})
export class CommonComponentsComponent {
  @Input() ppFleetLoaded = false;
  @Input() ppFromSite = false;
}
