import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SortingService } from '@core/services';
import { CalendarView } from 'angular-calendar';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { TReminder } from '../reminders.consts';

@Injectable({
  providedIn: 'root',
})
export class RemindersCalendarService {
  constructor(
    private previousPageService: PreviousPageService,
    private router: Router,
    private sortingService: SortingService,
  ) {}

  changeMode(mode: CalendarView): void {
    this.previousPageService.setPreviousRemindersUrl(this.router.url);

    this.router.navigate([], {
      queryParams: {
        mode: mode,
      },
    });
  }

  sortReminders(reminders: TReminder[]): TReminder[] {
    return reminders.sort((a, b) => {
      const primaryOrder = this.sortingService.naturalSort(
        a.timestampEpochMillis,
        b.timestampEpochMillis,
      );

      if (primaryOrder !== 0) {
        return primaryOrder;
      }

      return this.sortingService.naturalSort(a.name, b.name);
    });
  }
}
