import { TUser } from 'src/app/project/modules/user/user.model';
import { EUserType } from 'src/app/project/modules/users/user-types-enum';
import { VERSION } from 'src/environments/version';
import { EIconPath } from '../../project/shared/enums/icons.enum';

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export const consoleLogDimmed = (_name, _value) => {
  console.log(
    `%c${_name} ${_value}`,
    [
      'border-radius: 2.4rem;',
      'color: #999;',
      'font-size: 0.7em;',
      'font-style: italic;',
      'background-color: #222',
    ].join(''),
  );
};

export const consoleGroupDimmed = (_label, _list) => {
  console.groupCollapsed(
    `%c${_label}`,
    ['color: #999;', 'font-size: 0.7em;', 'font-style: italic;'].join(''),
  );
  _list.forEach((_idle) => {
    console.log(_idle);
  });
  console.groupEnd();
};

export const consoleLogVersion = () => {
  console.log(
    `%cApp version: ${VERSION.version}`,
    [
      'box-sizing: border-box;',
      'padding: 0 1rem;',
      'border-radius: 2.4rem;',
      'color: #fff;',
      'line-height: 1.8rem;',
      'font-weight: bold;',
      'background-color: #0084F8',
    ].join(''),
  );
};

export const consoleLogImage = (_url, _size, _callback) => {
  const image = new Image();

  image.onload = function () {
    const verticalPaddingPx = (image.height / 100) * (_size / 2);
    const horizontalPaddingPx = (image.width / 100) * (_size / 2);

    toDataURL(_url).then((dataUrl) => {
      console.log(
        '%c ',
        [
          `padding: ${verticalPaddingPx}px ${horizontalPaddingPx}px;`,
          'font-size: 0.1rem;',
          `background: url(${dataUrl}) no-repeat;`,
          'background-size: contain;',
        ].join(''),
      );

      _callback();
    });
  };

  image.src = _url;
};

consoleLogImage(EIconPath.LOGO, 60, () => {
  consoleLogVersion();
});

export function logDevMessage(user: TUser, message: any): void {
  if (user.userType === EUserType.DEVELOPER || user.userType === EUserType.TESTER) {
    console.log(message);
  }
}
