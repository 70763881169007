import { Component, Input } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TReminder, TReminderActionDTO } from '../../../reminders/reminders.consts';
import { RemindersService } from '../../../reminders/reminders.service';
import { ReminderDismissDropdownComponent } from './reminder-dismiss-dropdown/reminder-dismiss-dropdown.component';

@Component({
  selector: 'pp-reminder-dismiss-button',
  templateUrl: './reminder-dismiss-button.component.html',
  styleUrl: './reminder-dismiss-button.component.scss',
})
export class ReminderDismissButtonComponent {
  @Input() ppReminder: TReminder;
  @Input() ppReminders: TReminder[];

  EIconPath = EIconPath;
  dropdown: TDropdown;
  buttonId = 'reminderDismissButton';

  constructor(
    private remindersService: RemindersService,
    private dropdownService: DropdownService,
  ) {
    this.dropdown = this.dropdownService.getDropdown();
  }

  dismissReminder(bulk: boolean): void {
    const body: TReminderActionDTO = {
      reminderIds: bulk
        ? this.ppReminders.map((reminder) => reminder.reminderId)
        : [this.ppReminder.reminderId],
    };

    this.remindersService.dismissReminders(body).subscribe();
  }

  toogleDismissDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({});

      this.dropdownService.showDropdown(this.buttonId, ReminderDismissDropdownComponent, {
        callback: (option) => this.dismissReminder(option.bulk),
        popper: {
          positionFixed: true,
          placement: 'top',
        },
        width: '119px',
      });
    }
  }
}
