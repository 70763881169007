import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pp-create-multi-list-from-excel-button',
  templateUrl: './create-multi-list-from-excel-button.component.html',
  styleUrl: './create-multi-list-from-excel-button.component.scss',
})
export class CreateMultiListFromExcelButtonComponent {
  @Output() ppAction = new EventEmitter<File[]>();

  emitAction(files: File[]): void {
    this.ppAction.emit(files);
  }
}
