import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { TReminder } from '../../../reminders.consts';
import { RemindersCalendarService } from '../../reminders-calendar.service';

@Component({
  selector: 'pp-reminders-calendar-month-view-cell',
  templateUrl: './reminders-calendar-month-view-cell.component.html',
  styleUrl: './reminders-calendar-month-view-cell.component.scss',
})
export class RemindersCalendarMonthViewCellComponent {
  @Input() ppLocale: string;
  @Input() ppDay: any;
  @Input() ppView: CalendarView;
  @Output() ppViewChange = new EventEmitter<CalendarView>();
  @Input() ppViewDate: Date;
  @Output() ppViewDateChange = new EventEmitter<Date>();

  reminders: TReminder[] = [];

  constructor(private remindersCalendarService: RemindersCalendarService) {}

  ngOnChanges(): void {
    this.setReminders();
  }

  openDay(day: Date): void {
    this.remindersCalendarService.changeMode(CalendarView.Day);
    this.ppViewDateChange.emit(day);
  }

  private setReminders(): void {
    this.reminders = this.remindersCalendarService.sortReminders(
      this.ppDay.events.map((event) => event.reminder),
    );
  }
}
