import { Component, Input } from '@angular/core';
import { WeekViewHourSegment } from 'calendar-utils';
import dayjs from 'dayjs';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

@Component({
  selector: 'pp-reminders-calendar-week-view-time-label-cell',
  templateUrl: './reminders-calendar-week-view-time-label-cell.component.html',
  styleUrl: './reminders-calendar-week-view-time-label-cell.component.scss',
})
export class RemindersCalendarWeekViewTimeLabelCellComponent {
  @Input() ppSegment: WeekViewHourSegment;
  daysInWeek = 7;
  displayDate: string;

  constructor(private preferencesService: PreferencesService) {}

  ngOnChanges(): void {
    const dateFormat = this.preferencesService.getPreferences().dateFormat;

    const hourFormat =
      dateFormat === EDateFormat.EUROPEAN || dateFormat === EDateFormat.ISO ? 'H' : 'h A';

    this.displayDate = dayjs(this.ppSegment.displayDate).format(hourFormat);
  }
}
