import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { DEFAULT_DATE_FORMAT } from '../../../preferences/default-date-format';
import { TPreferences } from '../../../preferences/preferences.model';
import { TReminder } from '../../../reminders/reminders.consts';
import { getReminderFormattedTimestamp } from '../../../reminders/utils/get-reminder-formatted-timestamp';

@Component({
  selector: 'pp-reminder-timestamp',
  templateUrl: './reminder-timestamp.component.html',
  styleUrl: './reminder-timestamp.component.scss',
})
export class ReminderTimestampComponent implements OnChanges, OnInit {
  @Input() ppReminder: TReminder;

  EIconPath = EIconPath;
  timeText: string;
  isSnoozed: boolean = false;

  private refreshTimeIntervalStep: number;
  private refreshTimeInterval: NodeJS.Timeout;
  private readonly destroy$ = new Subject<void>();
  private format: EDateFormat = DEFAULT_DATE_FORMAT;
  private preferences$: Observable<TPreferences>;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });
  }

  ngOnInit(): void {
    this.isSnoozed =
      this.ppReminder.alertEpochMillis !== this.ppReminder.timestampEpochMillis &&
      !this.ppReminder.dismissed;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(): void {
    if (this.refreshTimeInterval) {
      clearInterval(this.refreshTimeInterval);
    }

    this.setTimeText();
    this.calculateRefreshTimeIntervalStep();

    this.refreshTimeInterval = setInterval(() => {
      this.setTimeText();
      this.calculateRefreshTimeIntervalStep();
    }, this.refreshTimeIntervalStep);
  }

  private setTimeText(): void {
    this.timeText = getReminderFormattedTimestamp(this.ppReminder.alertEpochMillis, this.format);
  }

  private calculateRefreshTimeIntervalStep(): void {
    const date = dayjs(this.ppReminder.alertEpochMillis);
    const diff = date.diff(dayjs(), 'seconds');

    if (diff < 60) {
      this.refreshTimeIntervalStep = 1000;
    } else if (diff < 3600) {
      this.refreshTimeIntervalStep = 60000;
    } else {
      this.refreshTimeIntervalStep = 3600000;
    }
  }
}
