import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { ESnoozePeriod } from '../reminder-snooze-button-consts';
import { ReminderSnoozeButtonService } from '../reminder-snooze-button.service';
import { TReminderSnoozeDropdownData } from './reminder-snooze-dropdown-consts';

@Component({
  selector: 'pp-reminder-snooze-dropdown',
  templateUrl: './reminder-snooze-dropdown.component.html',
  styleUrl: './reminder-snooze-dropdown.component.scss',
})
export class ReminderSnoozeDropdownComponent {
  selectOptions: {
    key: TTranslationKey;
    value: ESnoozePeriod;
    date?: string;
  }[] = [
    { key: '1hour', value: ESnoozePeriod.HOUR },
    { key: '1day', value: ESnoozePeriod.DAY },
    { key: '1week', value: ESnoozePeriod.WEEK },
  ];

  showMultipleRemindersOptions: boolean;
  private dropdown: TDropdown<TReminderSnoozeDropdownData>;

  constructor(
    private dropdownService: DropdownService,
    private preferencesService: PreferencesService,
    private reminderSnoozeButtonService: ReminderSnoozeButtonService,
  ) {
    this.dropdown = this.dropdownService.getDropdown();
    this.showMultipleRemindersOptions = this.dropdown.data.multipleReminders;

    this.setOptionDates();
  }

  selectOption(option: ESnoozePeriod, bulk: boolean): void {
    this.dropdown.callback({
      snoozePeriod: option,
      bulk,
    });

    this.dropdownService.hideDropdown();
  }

  private setOptionDates(): void {
    const dateFormat = this.preferencesService.getPreferences().dateFormat;
    let hourFormat = 'hh:mm A';

    if (dateFormat === EDateFormat.EUROPEAN || dateFormat === EDateFormat.ISO) {
      hourFormat = 'HH:mm';
    }

    this.selectOptions.forEach((option) => {
      const now = this.reminderSnoozeButtonService.approximateDateToFull15Minutes(new Date());

      switch (option.value) {
        case ESnoozePeriod.HOUR:
          now.setHours(now.getHours() + 1);

          option.date = dayjs(+now.getTime()).format(hourFormat);

          break;
        case ESnoozePeriod.DAY:
          now.setDate(now.getDate() + 1);

          option.date = transformDate({
            value: now,
            inputHourFormat: `, ${hourFormat}`,
            format: dateFormat,
            localTime: true,
          });

          break;
        case ESnoozePeriod.WEEK:
          now.setDate(now.getDate() + 7);

          option.date = transformDate({
            value: now,
            inputHourFormat: `, ${hourFormat}`,
            format: dateFormat,
            localTime: true,
          });
          break;
      }
    });
  }
}
