import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';

export const API_workspace_all_users = (): string => '/api/v3/workspace/all-users';
export const API_workspace_customm_fields = (workspaceId: string): string =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates`;
export const API_workspace_custom_fields_excel_list = (
  workspaceId: string,
  listType = ECustomFieldType.LIST,
): string =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates/list-excel-import?listType=${listType}`;
export const API_workspace_custom_field = (workspaceId: string, customFieldId: string): string =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates/${customFieldId}`;
export const API_workspace_S3_credentials = (workspaceId: string): string =>
  `/api/v1/workspaces/sites/${workspaceId}/site-plan-tiles/s3-temporary-credentials`;
export const API_workspace_site_plan = (workspaceId: string, planFormat: string): string =>
  `/api/v1/workspaces/sites/plans/${workspaceId}?outputEncoding=${planFormat}`;
export const API_workspace_site_plan_import = (
  sourceWorkspaceId: string,
  targetWorkspaceId: string,
): string => `/api/v1/workspaces/sites/plans/from/${sourceWorkspaceId}/to/${targetWorkspaceId}`;
export const API_workspace_name = (workspaceId: string): string =>
  `/api/v1/workspaces/sites/${workspaceId}/name`;
export const API_workspace_tags = (workspaceId: string): string =>
  `/api/v1/workspaces/sites/${workspaceId}/tags`;
export const API_workspace_custom_field_import = (workspaceId: string): string =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates/other-site-import`;
export const API_workspace_users = (workspaceId: string): string =>
  `/api/v2/workspace/${workspaceId}/users`;
export const API_workspace = (workspaceId: string): string => `api/v1/workspace/${workspaceId}`;
export const API_workspace_update_cf_list = (workspaceId: string): string =>
  `api/v1/workspace/${workspaceId}/update-cf-list`;
export const API_workspace_account_site = (accountId: string): string =>
  `/api/v1/workspaces/sites/${accountId}`;
export const API_workspace_archive = (): string => `/api/v1/workspace/archive`;
export const API_workspace_unarchive = (): string => `/api/v1/workspace/unarchive`;
export const API_workspace_compare_formulas = (): string =>
  `/api/v1/workspaces/dupa/custom-field-templates/group-formulas`;
export const API_workspace_display = (
  workspaceId: string,
  customFieldId: string,
  display: boolean,
): string =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates/${customFieldId}?display=${display}`;
