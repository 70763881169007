import { Component } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

@Component({
  selector: 'pp-reminder-dismiss-dropdown',
  templateUrl: './reminder-dismiss-dropdown.component.html',
  styleUrl: './reminder-dismiss-dropdown.component.scss',
})
export class ReminderDismissDropdownComponent {
  private dropdown: TDropdown;

  constructor(private dropdownService: DropdownService) {
    this.dropdown = this.dropdownService.getDropdown();
  }

  selectOption(bulk: boolean): void {
    this.dropdown.callback({
      bulk,
    });

    this.dropdownService.hideDropdown();
  }
}
