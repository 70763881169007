import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TClassList } from '@core/helpers';
import { TButtonSize, TButtonStyle } from './button.model';

@Component({
  selector: 'pp-button',
  templateUrl: './button.component.html',
  styleUrls: [
    './button.component.scss',
    './button-primary.component.scss',
    './button-secondary.component.scss',
    './button-text.component.scss',
  ],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() ppDisabled: boolean;
  @Input() ppProcess: boolean;
  @Input() ppTooltipTitle: string;

  @Input() ppId: string;
  @Input() ppSize: TButtonSize;
  @Input() ppStyle: TButtonStyle = 'secondary';
  @Input() ppWide: boolean;
  @Input() ppWarning: boolean = false;

  @Output() ppAction = new EventEmitter<MouseEvent>();

  class: TClassList;
  lightSpinner = false;

  constructor() {}

  ngOnInit() {
    this.generateClass();
  }

  ngOnChanges() {
    this.generateClass();
  }

  generateClass(): void {
    switch (this.ppStyle) {
      case 'secondary':
        this.class = {
          buttonSecondary: true,
          'buttonSecondary--x-small': this.ppSize === 'x-small',
          'buttonSecondary--small': this.ppSize === 'small',
          'buttonSecondary--large': this.ppSize === 'large',
          'button--wide': this.ppSize === 'wide',
          'buttonSecondary--disabled': this.ppDisabled,
          'buttonSecondary--warning': this.ppWarning,
        };

        break;
      case 'primary':
        this.class = {
          buttonPrimary: true,
          'buttonPrimary--small': this.ppSize === 'small',
          'buttonPrimary--large': this.ppSize === 'large',
          'button--wide': this.ppSize === 'wide' || this.ppWide,
          'buttonPrimary--disabled': this.ppDisabled,
          'buttonPrimary--warning': this.ppWarning,
        };

        this.lightSpinner = true;
        break;
      case 'primaryRounded':
        this.class = {
          'buttonPrimary--rounded': true,
          'buttonPrimary--small': this.ppSize === 'small',
          'buttonPrimary--large': this.ppSize === 'large',
          'button--wide': this.ppSize === 'wide' || this.ppWide,
          'buttonPrimary--disabled': this.ppDisabled,
        };

        this.lightSpinner = true;
        break;
      case 'text':
        this.class = {
          buttonText: true,
          'buttonText--disabled': this.ppDisabled,
        };
        this.lightSpinner = true;
        break;
    }
  }

  action(event: MouseEvent): void {
    if (!this.ppProcess && this.ppAction && !this.ppDisabled) {
      this.ppAction.emit(event);
    }
  }
}
