import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import {
  SetActivePointId,
  SetActiveWorkspaceId,
} from 'src/app/project/services/active/active.actions';

import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointAttachmentsService } from '../point-modal/point-attachments/point-attachments.service';

export type TPointHalfModal = {
  visible: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PointHalfModalService {
  openedPointId = '';

  private modal: TPointHalfModal = {
    visible: false,
  };

  private workspaceId: string = null;

  constructor(
    private store: Store,
    private router: Router,
    private pointAttachmentsService: PointAttachmentsService,
    private activeService: ActiveService,
  ) {}

  getModal(): TPointHalfModal {
    return this.modal;
  }

  showModal(
    workspaceId: string,
    _id: string,
    isOverview: boolean = false,
    isReminders: boolean = false,
  ): void {
    this.workspaceId = workspaceId;
    this.openedPointId = _id;

    if (!this.modal.visible) {
      this.modal.visible = true;
    }

    if (!isOverview && !isReminders) {
      this.store.dispatch(new SetActiveWorkspaceId(workspaceId));
    } else {
      this.store.dispatch(new SetActiveWorkspaceId(null));
    }

    this.store.dispatch(new SetActivePointId(_id));
  }

  hideModal(returnToSite: boolean = true, error: boolean = false): Promise<boolean> {
    this.openedPointId = '';
    this.workspaceId = this.activeService.getActiveWorkspaceId();

    const isTimeline = this.router.url.search(EWorkspaces.TIMELINE) !== -1;
    const isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;

    if (this.modal.visible || error) {
      this.modal.visible = false;

      this.pointAttachmentsService.cancelUploads();
      this.store.dispatch(new SetActivePointId(null));

      if (returnToSite) {
        if (isTimeline) {
          return this.router.navigate(['/site/timeline']);
        } else if (isOverview) {
          return this.router.navigate(['/site/overview']);
        } else {
          return this.router.navigate(['/site', this.workspaceId]);
        }
      }
    } else {
      return Promise.resolve(true);
    }
  }

  getOpenedPointId(): string {
    return this.openedPointId;
  }
}
