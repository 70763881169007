import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { WeekViewHourSegment } from 'calendar-utils';
import { TReminder } from '../../../reminders.consts';
import { TReminderEvent } from '../../reminder-calendar-consts';
import { RemindersCalendarService } from '../../reminders-calendar.service';

@Component({
  selector: 'pp-reminders-calendar-week-view-cell',
  templateUrl: './reminders-calendar-week-view-cell.component.html',
  styleUrl: './reminders-calendar-week-view-cell.component.scss',
})
export class RemindersCalendarWeekViewCellComponent {
  @Input() ppLocale: string;
  @Input() ppEvents: TReminderEvent[];
  @Input() ppSegment: WeekViewHourSegment;
  @Input() ppView: CalendarView;
  @Output() ppViewChange = new EventEmitter<CalendarView>();
  @Input() ppViewDate: Date;
  @Output() ppViewDateChange = new EventEmitter<Date>();

  reminders: TReminder[] = [];
  isStart: boolean;

  constructor(private remindersCalendarService: RemindersCalendarService) {}

  ngOnChanges(): void {
    this.setReminders();

    const date = this.ppSegment.date;
    const hour = date.getHours();

    if (hour === 0) {
      this.isStart = true;
    }
  }

  openDay(day: Date): void {
    this.remindersCalendarService.changeMode(CalendarView.Day);
    this.ppViewDateChange.emit(day);
  }

  private setReminders(): void {
    const hourInMs = 60 * 60 * 1000;
    const startDate = this.ppSegment.date.getTime();
    const endDate = this.ppSegment.date.getTime() + hourInMs;

    this.reminders = this.remindersCalendarService.sortReminders(
      this.ppEvents
        .map((event) => event.reminder)
        .filter((reminder) => {
          const reminderDate = reminder.timestampEpochMillis;
          return reminderDate >= startDate && reminderDate < endDate;
        }),
    );
  }
}
