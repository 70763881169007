<div class="reminderCalendarWeekHeaderCell">
  <span
    [class.reminderCalendarWeekHeaderDayNumber--today]="ppDay.isToday"
    [class.reminderCalendarWeekHeaderDayNumber--notToday]="!ppDay.isToday"
    class="reminderCalendarWeekHeaderDayNumber"
    ><span class="reminderCalendarWeekHeaderDayNumberInner">
      {{ displayDayNumber }}
    </span></span
  >
  <span class="reminderCalendarWeekHeaderName">{{ displayDayName }}</span>
</div>
