import { TPoint } from '../../../points/points.model';
import { isPointFlagged } from '../../../points/utils/is-flagged';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterFlag } from '../../models/advanced-filter.model';

export function filterFlagAdvanced(point: TPoint, filter: TAdvancedFilterFlag): boolean {
  const fieldValue: boolean = isPointFlagged(point);

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_FLAGGED:
      return fieldValue;
    case EAdvancedFilterOptions.IS_NOT_FLAGGED:
      return !fieldValue;
  }
}
