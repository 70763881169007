import dayjs from 'dayjs';
import _ from 'lodash';
import { translate } from 'src/app/project/features/translate/translate';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

export function generateTimeText(timestamp: number, dateFormat: EDateFormat): string {
  const date = dayjs(_.toNumber(timestamp));
  const today = dayjs();

  let hourFormat = 'hh:mm A';

  if (dateFormat === EDateFormat.EUROPEAN || dateFormat === EDateFormat.ISO) {
    hourFormat = 'HH:mm';
  }

  if (date.isSame(today, 'day')) {
    return `${translate('today_at')} ${date.format(hourFormat)}`;
  } else {
    return transformDate({
      value: date,
      inputHourFormat: ` [${translate('at')}] ${hourFormat}`,
      format: dateFormat,
      localTime: true,
    });
  }
}
