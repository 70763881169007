import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActiveService } from '../../services/active/active.service';
import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { PlanDataService, TPlanData } from '../plan/plan-data.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { FilterKeywordService } from './filter-logic/filter-keyword.service';

import { EStore } from '../../shared/enums/store.enum';
import { TPoint, TPoints } from '../points/points.model';
import { RemindersService } from '../reminders/reminders.service';
import { checkCustomWorkspaceId } from '../workspace/workspace';
import { TWorkspacesById } from '../workspace/workspace.model';
import { checkAccounts } from './filter-logic/filter-accounts';
import { checkAssignees } from './filter-logic/filter-assignees';
import { checkCreatedBy } from './filter-logic/filter-created-by';
import { checkCreatedDate } from './filter-logic/filter-created-date';
import { checkCustomFields } from './filter-logic/filter-custom-fields';
import { checkExcludedTags } from './filter-logic/filter-excluded-tags';
import { checkLocation } from './filter-logic/filter-location';
import { checkPointWithFlag } from './filter-logic/filter-point-with-flag';
import { checkPointWithLocation } from './filter-logic/filter-point-with-location';
import { checkPriority } from './filter-logic/filter-priority';
import { checkReminders } from './filter-logic/filter-reminders';
import { checkSites } from './filter-logic/filter-sites';
import { checkStatus } from './filter-logic/filter-status';
import { checkTags } from './filter-logic/filter-tags';
import { checkUpdatedDate } from './filter-logic/filter-updated-date';
import { TAllFilters, TFilters } from './site-filter.model';

@Injectable({
  providedIn: 'root',
})
export class CheckPointService {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;
  private plan: TPlanData = this.planDataService.getPlan();

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaces: TWorkspacesById;
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      points: TPoints;
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private filterKeywordService: FilterKeywordService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private planDataService: PlanDataService,
    private activeService: ActiveService,
    private remindersService: RemindersService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[this.workspaceId];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();
      this.workspaces = workspaces;

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }

  checkPoint(point: TPoint, skipLocation: boolean = false): boolean {
    let match = true;

    if (this.filters) {
      if (!skipLocation) {
        match = checkLocation(point, this.filters, this.plan);
      }

      if (match && !this.filterKeywordService.checkKeyword(point, this.filters)) {
        match = false;
      }

      if (match) {
        const workspace = this.workspaceService.findWorkspace(point.workspaceRef.id);
        const customFields = this.customFieldsService.getCustomFields();
        const reminders = this.remindersService.getReminders();

        if (
          !checkAccounts(this.filters, workspace) ||
          !checkSites(point, this.filters, workspace, this.workspaces) ||
          !checkPriority(point, this.filters) ||
          !checkStatus(point, this.filters) ||
          !checkTags(point, this.filters) ||
          !checkExcludedTags(point, this.filters) ||
          !checkCreatedBy(point, this.filters) ||
          !checkAssignees(point, this.filters) ||
          !checkCreatedDate(point, this.filters) ||
          !checkUpdatedDate(point, this.filters) ||
          !checkReminders(point, this.filters, reminders) ||
          !checkCustomFields(point, this.filters, customFields) ||
          !checkPointWithLocation(point, this.filters) ||
          !checkPointWithFlag(point, this.filters)
        ) {
          match = false;
        }
      }
    }

    return match;
  }
}
