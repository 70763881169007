<div class="remindersCalendar" #calendar>
  <pp-reminder-calendar-control-buttons
    [ppLocale]="locale"
    [(ppView)]="calendarView"
    [(ppViewDate)]="viewDate"
    (ppViewChange)="onViewChange($event)"
    (ppViewDateChange)="scrollToContentInView()"
    [ppDataFetched]="ppDataFetched"
  ></pp-reminder-calendar-control-buttons>

  <div class="remindersCalendar__calendarContainer">
    <ng-container [ngSwitch]="calendarView">
      <mwl-calendar-month-view
        #periodView
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [activeDayIsOpen]="true"
        [weekStartsOn]="1"
        [cellTemplate]="customCellTemplate"
        [openDayEventsTemplate]="openDayEventsTemplate"
        [locale]="locale"
        class="remindersCalendar__periodView remindersCalendar__scrollable"
      ></mwl-calendar-month-view>

      <mwl-calendar-week-view
        #periodView
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [weekStartsOn]="1"
        [locale]="locale"
        [hourSegments]="1"
        [hourSegmentHeight]="80"
        [eventTemplate]="weekEventTemplate"
        [hourSegmentTemplate]="customWeekViewTemplate"
        [currentTimeMarkerTemplate]="weekEventTemplate"
        [headerTemplate]="weekHeaderTemplate"
        class="remindersCalendar__periodView remindersCalendar__scrollable"
      ></mwl-calendar-week-view>

      <mwl-calendar-day-view
        #periodView
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [dayStartHour]="0"
        [dayEndHour]="24"
        [hourSegments]="1"
        [hourSegmentHeight]="80"
        [eventTemplate]="weekEventTemplate"
        [hourSegmentTemplate]="customDayViewTemplate"
        class="remindersCalendar__periodView remindersCalendar__scrollable"
      ></mwl-calendar-day-view>
    </ng-container>
    <div *ngIf="pointsFiltered" class="remindersCalendar__listView">
      <pp-reminders-calendar-list [ppEvents]="events"></pp-reminders-calendar-list>
    </div>
  </div>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <pp-reminders-calendar-month-view-cell
    [ppDay]="day"
    [ppLocale]="locale"
    [(ppView)]="calendarView"
    [(ppViewDate)]="viewDate"
    (ppViewChange)="onViewChange($event)"
  ></pp-reminders-calendar-month-view-cell>
</ng-template>

<ng-template #openDayEventsTemplate let-day="day" let-locale="locale"> </ng-template>
<ng-template #weekEventTemplate></ng-template>

<ng-template #customWeekViewTemplate let-segment="segment" let-isTimeLabel="isTimeLabel">
  <pp-reminders-calendar-week-view-cell
    [ppEvents]="events"
    [ppLocale]="locale"
    [ppSegment]="segment"
    [(ppView)]="calendarView"
    [(ppViewDate)]="viewDate"
    *ngIf="!isTimeLabel"
  ></pp-reminders-calendar-week-view-cell>

  <pp-reminders-calendar-week-view-time-label-cell
    [ppSegment]="segment"
    *ngIf="isTimeLabel"
  ></pp-reminders-calendar-week-view-time-label-cell>
</ng-template>

<ng-template #weekHeaderTemplate let-days="days" let-locale="locale">
  <pp-reminder-calendar-week-view-header
    [ppDays]="days"
    [ppLocale]="locale"
    [(ppView)]="calendarView"
    [(ppViewDate)]="viewDate"
    (ppViewChange)="onViewChange($event)"
  ></pp-reminder-calendar-week-view-header>
</ng-template>

<ng-template #customDayViewTemplate let-segment="segment" let-isTimeLabel="isTimeLabel">
  <pp-reminders-calendar-day-view-cell
    [ppEvents]="events"
    [ppLocale]="locale"
    [ppSegment]="segment"
  ></pp-reminders-calendar-day-view-cell>
</ng-template>
