import { translate } from 'src/app/project/features/translate/translate';
import { EPriority } from '../../points/priorities';

export function getReminderPriorityData(reminderPriority: EPriority): {
  priorityClass: string;
  priorityText: string;
} {
  switch (reminderPriority) {
    case EPriority.HIGH:
      return {
        priorityClass: 'reminderPointReference__priority--high',
        priorityText: translate('high'),
      };
      break;
    case EPriority.MEDIUM:
      return {
        priorityClass: 'reminderPointReference__priority--medium',
        priorityText: translate('medium'),
      };
    case EPriority.LOW:
      return {
        priorityClass: 'reminderPointReference__priority--low',
        priorityText: translate('low'),
      };
  }
}
