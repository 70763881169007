<div class="reminderSnoozeDropdown">
  <div class="reminderSnoozeDropdown__options">
    <header class="reminderSnoozeDropdown__header">{{ 'snooze' | ppTranslate }}</header>

    <ul class="reminderSnoozeDropdown__options">
      <li
        *ngFor="let option of selectOptions"
        class="reminderSnoozeDropdown__option"
        (click)="selectOption(option.value, false)"
      >
        <span class="reminderSnoozeDropdown__optionName">{{ option.key | ppTranslate }}</span>

        <span class="reminderSnoozeDropdown__optionDate"> {{ option.date }}</span>
      </li>
    </ul>
  </div>

  <ng-content *ngIf="showMultipleRemindersOptions">
    <div class="reminderSnoozeDropdownOptions__line">
      <div class="reminderSnoozeDropdown__lineInner"></div>
    </div>

    <div class="reminderSnoozeDropdown__options">
      <header class="reminderSnoozeDropdown__header">{{ 'snooze_all' | ppTranslate }}</header>

      <ul class="reminderSnoozeDropdown__options">
        <li
          *ngFor="let option of selectOptions"
          class="reminderSnoozeDropdown__option"
          (click)="selectOption(option.value, true)"
        >
          <span class="reminderSnoozeDropdown__optionName">{{ option.key | ppTranslate }}</span>

          <span class="reminderSnoozeDropdown__optionDate"> {{ option.date }}</span>
        </li>
      </ul>
    </div>
  </ng-content>
</div>
