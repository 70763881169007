import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointReminderModalComponent } from '../../points/point-reminders/point-reminder-modal/point-reminder-modal.component';
import { TReminderModalData } from '../../points/point-reminders/point-reminder-modal/point-reminder-modal.consts';
import { TReminder } from '../../reminders/reminders.consts';

@Component({
  selector: 'pp-reminder-alert',
  templateUrl: './reminder-alert.component.html',
  styleUrl: './reminder-alert.component.scss',
})
export class ReminderAlertComponent {
  @Input() ppReminder: TReminder;
  @Input() ppHighlighted: boolean;
  @Output() ppSelect = new EventEmitter<void>();

  constructor(private modalService: ModalService) {}

  selectReminder(): void {
    this.ppSelect.emit();
  }

  openReminderModal(): void {
    const modalData: TReminderModalData = {
      reminder: this.ppReminder,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(PointReminderModalComponent);
  }
}
