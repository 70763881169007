<!-- Preferences -->
<pp-grid class="settingsGrid__item--preferences" [ppOptions]="{ bodyPadding: true }">
  <ng-container title> {{ 'reminder_settings' | ppTranslate }} </ng-container>

  <ng-container body>
    <section class="remindersOptions">
      <div class="remindersOption" *ngFor="let option of options; let index = index">
        <section class="remindersOption__left">
          <h5 class="remindersOption__header">{{ option.header }}</h5>
          <p class="remindersOption__subheader">{{ option.subheader }}</p>
        </section>
        <pp-toggle
          ppStyle="blue"
          [ppChecked]="option.enabled"
          (ppAction)="toggleOption(option, index)"
        ></pp-toggle>
      </div>
    </section>
  </ng-container>
</pp-grid>
