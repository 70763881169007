import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { getTimezone } from './point-reminder-modal-time-input-utils';

@Component({
  selector: 'pp-point-reminder-modal-time-input',
  templateUrl: './point-reminder-modal-time-input.component.html',
  styleUrl: './point-reminder-modal-time-input.component.scss',
})
export class PointReminderModalTimeInputComponent {
  @Input() ppTime: string;
  @Output() ppTimeChange = new EventEmitter<string>();

  options: string[] = [];
  time: string;
  timeFormat: 'am-pm' | '24h';
  EIconPath = EIconPath;
  timezone: string;

  constructor(private preferencesService: PreferencesService) {
    const timeFormat = this.preferencesService.getPreferences().dateFormat;
    this.timezone = getTimezone();

    if (timeFormat === EDateFormat.US || timeFormat === EDateFormat.FRIENDLY) {
      this.timeFormat = 'am-pm';
    } else {
      this.timeFormat = '24h';
    }

    this.setOptions();
  }

  ngOnChanges(): void {
    const time = this.formatTime();
    this.time = time + ` ${this.timezone}`;
  }

  onTimeChange(time: string): void {
    const formattedTime = time.replace(` ${this.timezone}`, '');

    if (this.timeFormat === 'am-pm') {
      this.ppTimeChange.emit(this.convertAMPMTimeTo24(formattedTime));

      return;
    }

    this.ppTimeChange.emit(formattedTime);
  }

  private setOptions(): void {
    let options = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let quarter = 0; quarter < 4; quarter++) {
        switch (this.timeFormat) {
          case 'am-pm':
            options.push(
              `${(hour % 12 || 12).toString().padStart(2, '0')}:${(quarter * 15).toString().padStart(2, '0')} ${hour < 12 ? 'AM' : 'PM'}`,
            );
            break;
          default:
            options.push(
              `${hour.toString().padStart(2, '0')}:${(quarter * 15).toString().padStart(2, '0')}`,
            );
        }
      }
    }

    options.forEach((option, index) => (options[index] = option + ` ${this.timezone}`));
    this.options = options;
  }

  formatTime(): string {
    switch (this.timeFormat) {
      case 'am-pm':
        return this.convertNormalTimeToAMPM(this.ppTime);
      default:
        return this.ppTime;
    }
  }

  convertAMPMTimeTo24(time: string): string {
    const [timePart, period] = time.split(' ');

    const [hours, minutes] = timePart.split(':');

    if (period === 'PM') {
      return `${((parseInt(hours, 10) % 12) + 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    return timePart;
  }

  convertNormalTimeToAMPM(time: string): string {
    const [hours, minutes] = time.split(':');

    const hour = parseInt(hours, 10);

    if (hour === 0) {
      return `12:${minutes.toString().padStart(2, '0')} AM`;
    }

    if (hour < 12) {
      return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} AM`;
    }

    if (hour === 12) {
      return `12:${minutes.toString().padStart(2, '0')} PM`;
    }

    return `${(hour - 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} PM`;
  }
}
