import { Injectable } from '@angular/core';
import { logDevMessage } from '@core/helpers';
import { IMessage } from '@stomp/stompjs';
import { logErrorInSentry } from '../errors/response-error';
import { TRemindersSocketMessage } from '../reminders/reminders.consts';
import { RemindersService } from '../reminders/reminders.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SocketsRemindersService {
  constructor(
    private userService: UserService,
    private remindersService: RemindersService,
  ) {}

  subscribeCallback(_message: IMessage): void {
    this.logDevMessage('Websocket - received a message in reminders');
    this.logDevMessage(_message);

    if (_message.body) {
      this.handleRemindersMessage(_message.body);
    } else {
      this.logDevMessage('Websocket - received an empty reminders message');
    }
  }

  checkForRemindersAfterReconnect(): void {
    this.remindersService.getFilteredReminders(new Date().getTime()).subscribe();
  }

  private logDevMessage(message): void {
    const user = this.userService.getUser();

    logDevMessage(user, message);
  }

  private handleRemindersMessage(_message: string): void {
    const reminders = this.parseReminders(_message);
    this.remindersService.updateRemindersFromSockets(reminders.reminders);
  }

  private parseReminders(message: string): TRemindersSocketMessage {
    try {
      return JSON.parse(message);
    } catch (error) {
      this.logDevMessage('Websocket - error parsing reminders message');
      this.logDevMessage(error);
      logErrorInSentry(error);

      return {
        reminders: [],
      };
    }
  }
}
