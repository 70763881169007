<div class="base">
  <pp-common-components></pp-common-components>

  <div class="dashboard">
    <gridster
      *ngIf="dashlets"
      class="dashboard__content"
      [class.dashboard__content--full-width]="!sidePanel.expandedInitially"
      [class.dashboard__content--down]="ui.actionBar"
      [options]="gridsterOptions"
    >
      <gridster-item
        [item]="dashlet"
        *ngFor="let dashlet of dashlets; trackBy: dashletTrackByName"
        [ngClass]="dashlet.name"
        class="dashboard__gridster-item"
      >
        <pp-dashlet
          *ngIf="workspacesFetched"
          [ppDashlet]="dashlet"
          (ppUpdateUserDashlets)="updateUserDashlets()"
          [ppCanEdit]="true"
        ></pp-dashlet>
      </gridster-item>

      <ng-container *ngIf="!dashlets && !dashlets.length && offline">
        <h5 class="dashboard__gridster-item-not-available">
          {{ 'data_unavailable_offline' | ppTranslate }}
        </h5>
      </ng-container>
    </gridster>
  </div>
</div>
