import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ScreenService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { WorkspaceService } from '../../workspace/workspace.service';
import { PointsService } from '../points.service';

@Component({
  selector: 'pp-point-reminders',
  templateUrl: './point-reminders.component.html',
  styleUrls: ['./point-reminders.component.scss'],
})
export class PointRemindersComponent {
  private readonly destroy$ = new Subject<void>();

  _id: string = '';
  type: string;
  private isDesktopScreen: boolean;
  sitePlanRightPanelVisible: boolean = false;
  isFullModal: boolean = false;
  private sitePlanExists: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private screenService: ScreenService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
  ) {}

  ngOnInit() {
    this.activatedRoute.parent.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => this.paramsHandler(params));

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      this.isDesktopScreen = isDesktop;

      this.checkView();
    });
  }

  private checkView(): void {
    this.isFullModal = this.type === 'full';
    this.sitePlanRightPanelVisible =
      this.isDesktopScreen && this.type === 'full' && this.sitePlanExists;
  }

  paramsHandler(params: Params): void {
    this._id = params['params']._id;
    this.type = this.activatedRoute.snapshot.data.type;

    const point = this.pointsService.findPoint(this._id);
    const workspace = this.workspaceService.findWorkspace(point.workspaceRef.id);
    this.sitePlanExists = !!workspace.siteImageRef;

    this.checkView();
  }
}
