import dayjs from 'dayjs';
import { translate } from 'src/app/project/features/translate/translate';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

export function getReminderFormattedTimestamp(timestamp: number, format: EDateFormat): string {
  const date = dayjs(timestamp);

  const timeDifference = Math.abs(date.valueOf() - dayjs().valueOf());
  const dayInMs = 86400000;
  const minuteInMs = 60000;

  if (timeDifference > 7 * dayInMs) {
    let inputHourFormat = ' [at] hh:mm A';

    if (format === EDateFormat.EUROPEAN || format === EDateFormat.ISO) {
      inputHourFormat = ' [at] HH:mm';
    }

    return transformDate({
      value: date,
      inputHourFormat,
      format: format,
      localTime: false,
    });
  } else if (timeDifference > minuteInMs) {
    return date.fromNow();
  } else {
    return translate('just_now');
  }
}
