<div
  class="reminder__container"
  [class.reminder__container--dismissed]="isDismissed"
  [class.reminder__container--overdue]="isOverdue"
  (click)="viewReminder()"
>
  <section class="reminder__inner" [class.reminder__inner--snoozed]="isSnoozed">
    <span class="reminder__reminderName">{{ ppReminder.name }}</span>
    <span class="reminder__dueDate">{{ reminderDueTime }}</span>
  </section>

  <div class="reminder__snooze" *ngIf="isSnoozed">
    <pp-icon
      ppColor="inherit"
      ppClass="setSize18"
      [ppSrc]="EIconPath.ICON_MISC_SNOOZE_18"
    ></pp-icon>

    {{ reminderSnoozeTime }}
  </div>
</div>
