<article
  class="point__container"
  [class.pointReminders--half]="!isFullModal"
  [class.pointReminders--full]="isFullModal && !sitePlanRightPanelVisible"
  [class.pointReminders--sitePlan]="sitePlanRightPanelVisible"
>
  <div class="point__backdrop">
    <div class="point__bg">
      <pp-point-reminders-header
        [ppPointId]="_id"
        [ppHide]="isFullModal && !sitePlanRightPanelVisible"
      ></pp-point-reminders-header>
      <pp-point-reminders-list [ppPointId]="_id"></pp-point-reminders-list>
    </div>
  </div>
</article>
