import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterLocation } from '../../models/advanced-filter.model';

export function filterLocationAdvanced(point: TPoint, filter: TAdvancedFilterLocation): boolean {
  const fieldValue = point.polygons?.length > 0 || point.pins?.length > 0;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_EMPTY:
      return fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !fieldValue;
  }
}
