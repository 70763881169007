import { Component, Input } from '@angular/core';
import { WeekDay } from 'calendar-utils';
import dayjs from 'dayjs';

@Component({
  selector: 'pp-reminder-calendar-week-view-header-cell',
  templateUrl: './reminder-calendar-week-view-header-cell.component.html',
  styleUrl: './reminder-calendar-week-view-header-cell.component.scss',
})
export class ReminderCalendarWeekViewHeaderCellComponent {
  @Input() ppDay: WeekDay;
  @Input() ppLocale: string;

  displayDayNumber: string;
  displayDayName: string;

  ngOnChanges(): void {
    this.displayDayNumber = dayjs(this.ppDay.date).format('D');
    this.displayDayName = dayjs(this.ppDay.date).format('ddd');
  }
}
