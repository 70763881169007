<div class="cal-cell-top">
  <span class="cal-day-number" (click)="openDay(ppDay.date)">{{
    ppDay.date | calendarDate: 'monthViewDayNumber' : ppLocale
  }}</span>
</div>

<section class="calendarReminders">
  <ng-container *ngFor="let reminder of reminders; let index = index">
    <pp-reminder-calendar-element
      [ppReminder]="reminder"
      *ngIf="index < 3"
    ></pp-reminder-calendar-element>

    <button
      class="calendarEvent__more"
      *ngIf="index === 2 && reminders.length > 3"
      (click)="openDay(ppDay.date)"
    >
      <span>+{{ 'x_more' | ppTranslate: { count: reminders.length - 3 } }} </span>
    </button>
  </ng-container>
</section>
