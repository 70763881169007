import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { checkMultilistForMatches } from '../../../filters/utils/checkMultilistForMatches';
import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterMultiList } from '../../models/advanced-filter.model';

export function filterMultiListAdvanced(point: TPoint, filter: TAdvancedFilterMultiList): boolean {
  let fieldValue = point.customFieldsMap?.[filter.id]?.value;

  if (!fieldValue) {
    fieldValue = '';
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_EMPTY:
      return fieldValue.length === 0;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return fieldValue.length > 0;
  }

  if (!filter.value?.value?.split(', ').length) {
    return true;
  }
  const filterSelectedItems = filter.value.selectedIds;
  const cfs = GET_CUSTOM_FIELDS()[filter.id].subList;
  const filteredValue = checkMultilistForMatches(cfs, filterSelectedItems);

  const fieldValues = fieldValue.split(', ');

  const filterSelectedValues = filter.value.value.split(', ');

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_ALL_OF:
    case EAdvancedFilterOptions.IS:
      return filterSelectedValues.every((value) => fieldValues.includes(value));
    case EAdvancedFilterOptions.IS_NOT:
      return !filterSelectedValues.every((value) => fieldValues.includes(value));
    case EAdvancedFilterOptions.IS_ANY_OF:
      return filteredValue.some((value) => fieldValues.includes(value));
    case EAdvancedFilterOptions.IS_NONE_OF:
      return !filteredValue.some((value) => fieldValues.includes(value));
  }
}
