import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import { RemindersCalendarService } from '../../reminders-calendar.service';

@Component({
  selector: 'pp-reminder-calendar-week-view-header',
  templateUrl: './reminder-calendar-week-view-header.component.html',
  styleUrl: './reminder-calendar-week-view-header.component.scss',
})
export class ReminderCalendarWeekViewHeaderComponent {
  @Input() ppDays: WeekDay[];
  @Input() ppLocale: string;
  @Input() ppView: CalendarView;
  @Output() ppViewChange = new EventEmitter<CalendarView>();
  @Input() ppViewDate: Date;
  @Output() ppViewDateChange = new EventEmitter<Date>();

  constructor(private remindersCalendarService: RemindersCalendarService) {}

  openDay(day: Date) {
    this.remindersCalendarService.changeMode(CalendarView.Day);
    this.ppViewDateChange.emit(day);
  }

  trackByWeekDayHeaderDate = (index: number, day: WeekDay) => day.date.toISOString();
}
