import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { RemindersCalendarService } from '../../../reminders/reminders-calendar/reminders-calendar.service';
import { TReminder } from '../../../reminders/reminders.consts';
import { RemindersService } from '../../../reminders/reminders.service';

@Component({
  selector: 'pp-point-reminders-list',
  templateUrl: './point-reminders-list.component.html',
  styleUrls: ['./point-reminders-list.component.scss'],
})
export class PointRemindersListComponent implements OnInit {
  @Input() ppPointId: string = '';
  private readonly destroy$ = new Subject<void>();

  reminders: TReminder[] = [];

  constructor(
    private remindersService: RemindersService,
    private remindersCalendarService: RemindersCalendarService,
  ) {}

  ngOnInit(): void {
    this.updateList();

    this.remindersService.remindersChanged$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.updateList()),
      )
      .subscribe();
  }

  updateList(): void {
    this.reminders = this.remindersService
      .getReminders()
      .filter((reminder) => reminder.pointId === this.ppPointId);
    this.sortList();
  }

  sortList(): void {
    this.reminders = this.remindersCalendarService.sortReminders(this.reminders);
  }
}
