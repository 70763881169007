import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from '../site-filter.model';

import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { SiteFilterService } from 'src/app/project/modules/filters/site-filter-service/site-filter.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { ClearFilterService } from '../clear-filter-service/clear-filter.service';
import { SiteFilterDataService } from '../site-filter-data-service/site-filter-data.service';
import { SiteFilterDropdownService } from './site-filter-dropdown-service/site-filter-dropdown.service';

import { ClickOutsideHandler } from '@core/services';
import { cloneDeep } from 'lodash';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { EStore } from '../../../shared/enums/store.enum';
import { ResponseErrorService } from '../../errors/response-error.service';
import { AdvancedFilterService } from '../../filters-advanced/advanced-filter.service';
import { TAdvancedFilter } from '../../filters-advanced/models/advanced-filter.model';
import { TSavedView } from '../../saved-views/models/saved-view.model';
import { SavedViewsService } from '../../saved-views/saved-views.service';
import { ResetTableService } from '../../site/reset-table.service';
import { EWorkspaces } from '../../workspace/workspaces.enum';
import { checkAdvancedFiltersEnabled } from './site-filter-dropdown-utils/check-advanced-filters-enabled';
import { checkSavedViewsEnabled } from './site-filter-dropdown-utils/check-saved-views-enabled';
import { checkSitePlanExists } from './site-filter-dropdown-utils/check-site-plan-exists';
import { siteFilterClickOutside } from './site-filter-dropdown-utils/site-filter-click-outside';

@Component({
  selector: 'pp-site-filter-dropdown',
  templateUrl: './site-filter-dropdown.component.html',
  styleUrls: ['./site-filter-dropdown.component.scss'],
})
export class SiteFilterDropdownComponent implements OnInit, OnDestroy {
  @Input() ppDataFetched = true;
  filters: TFilters;
  customFields = this.customFieldsService.getCustomFields();
  isDropdownVisible: boolean;
  tagsVisible = false;
  workspaces: TWorkspacesById;
  filterButtonHovered = false;
  sitePlanExists = true;
  savedViewsEnabled = false;
  advancedFiltersEnabled = false;
  EIconPath = EIconPath;
  EWorkspaces = EWorkspaces;
  activeView: TSavedView;
  saveViewProcessing = false;

  private readonly destroy$ = new Subject<void>();

  private clickOutsideHandler: ClickOutsideHandler;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  workspaceId: string;
  advancedFilter: TAdvancedFilter;
  filterNumber: string;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private siteFilterService: SiteFilterService,
    private siteFilterDataService: SiteFilterDataService,
    private customFieldsService: CustomFieldsService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private permissionsService: PermissionsService,
    private accountService: AccountService,
    private clearFilterService: ClearFilterService,
    private siteFilterDropdownService: SiteFilterDropdownService,
    private advancedFilterService: AdvancedFilterService,
    private savedViewsService: SavedViewsService,
    private responseErrorService: ResponseErrorService,
    private resetTableService: ResetTableService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filters = this.siteFilterDataService.getFilters();

      this.filterNumber = this.getNumberOfFilters();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspacesHandler(workspaces);
    });

    this.advancedFilterService.advancedFiltersChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.advancedFilter = this.advancedFilterService.getAdvancedFilter(this.workspaceId);
      });
  }

  @HostListener('document:keydown.esc')
  onEscPress(): void {
    if (this.isDropdownVisible) {
      this.hideDropdown(true);
    }
  }

  ngOnInit() {
    this.isDropdownVisible = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onFilterDropdownRendered(filterDropdownElement: HTMLElement): void {
    this.clickOutsideHandler = new ClickOutsideHandler(filterDropdownElement, this.destroy$, {
      mouseEventTypes: 'mousedown',
    });
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  toggleDropdown(): void {
    if (this.isDropdownVisible) {
      this.hideDropdown(true);
    } else {
      this.showDropdown();
    }
  }

  hideDropdown(cancel: boolean = false): void {
    this.isDropdownVisible = false;

    this.clickOutsideHandler.disable();

    if (cancel) {
      this.siteFilterService.cancelFilters();
    }
  }

  applyFilters(): void {
    this.siteFilterDropdownService.applyFilters(this.filters, this.workspaceId);

    this.filterNumber = this.getNumberOfFilters();
  }

  clearFilters(): void {
    logEventInGTAG(EGoogleEventSite.SITE__FILTER__CLEAR_ALL, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.filters = this.resetTableService.resetFilters(this.workspaceId);
    this.hideDropdown();
  }

  filterButtonHover(): void {
    this.filterButtonHovered = true;
  }

  filterButtonHoverEnd(): void {
    this.filterButtonHovered = false;
  }

  blurInput(event: Event): void {
    this.blurInput(event);
  }

  showDropdown(): void {
    const workspace = this.workspaceService.getActiveWorkspace();
    this.workspaces = this.workspaceService.getWorkspaces();
    this.isDropdownVisible = true;
    this.siteFilterDropdownService.checkOverviewFilter(this.workspaceId);
    this.setFilters(workspace);

    this.clickOutsideHandler.enable();
  }

  private setFilters(workspace: TWorkspace): void {
    this.customFields = this.siteFilterDropdownService.getCustomFields(workspace);

    if (workspace) {
      const tagPermissions = this.permissionsService.getTagPermissions(workspace.workspaceId);

      this.tagsVisible = tagPermissions.read;
    } else {
      this.tagsVisible = true;
    }

    this.siteFilterDropdownService.sortFilters(this.filters, this.customFields);
  }

  private workspacesHandler(workspaces: TWorkspacesById): void {
    const accounts = this.accountService.getAccounts();
    this.workspaceId = this.activeService.getActiveWorkspaceId();

    if (!this.workspaceId) {
      this.workspaceId = checkCustomWorkspaceId();
    }

    this.savedViewsEnabled = checkSavedViewsEnabled(workspaces, accounts, this.workspaceId);
    this.advancedFiltersEnabled = checkAdvancedFiltersEnabled(
      workspaces,
      accounts,
      this.workspaceId,
    );
    this.sitePlanExists = checkSitePlanExists(workspaces, this.workspaceId);
    this.filters = this.siteFilterDataService.getFilters();
    this.customFields = this.customFieldsService.getCustomFields();
    this.advancedFilter = this.advancedFilterService.getAdvancedFilter(this.workspaceId);
  }

  private onClickOutside(event: MouseEvent): void {
    const shouldHideDropdown = siteFilterClickOutside(event);

    if (shouldHideDropdown) {
      event.preventDefault();
      event.stopPropagation();
      this.hideDropdown(true);
    }
  }

  switchToAdvanced(): void {
    this.advancedFilterService.setAdvancedFilter(this.workspaceId, {
      ...this.advancedFilter,
      enabled: !this.advancedFilter.enabled,
    });
    this.advancedFilter = this.advancedFilterService.getAdvancedFilter(this.workspaceId);
    this.applyFilters();
  }

  saveFiltersToView(): void {
    const viewId = this.savedViewsService.getSelectedViewId(this.workspaceId);
    const view = this.savedViewsService.getView(viewId);
    const advancedFiltersToSave = cloneDeep(
      this.advancedFilterService.getAdvancedFilter(this.workspaceId),
    );
    advancedFiltersToSave.filters = advancedFiltersToSave.filters.filter((filter) => filter.type);

    this.saveViewProcessing = true;

    view.filters = {
      basic: this.filters,
      advanced: advancedFiltersToSave,
    };

    this.savedViewsService
      .saveToView(view, this.workspaceId)
      .pipe(
        tap(() => {
          this.saveViewProcessing = false;
        }),
        catchError((error) => {
          this.saveViewProcessing = false;
          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe();
  }

  getNumberOfFilters(): string {
    if (this.advancedFilter?.enabled) {
      if (this.advancedFilter.filters.length) {
        return ' / ' + this.advancedFilter.filters.length.toString();
      }

      return '';
    } else {
      if (this.filters?.numberOfFilters) {
        return ' / ' + this.filters?.numberOfFilters.toString();
      }

      return '';
    }
  }
}
