import { TPointResponse } from 'src/app/project/view-models/point-response.model';
import { TPoint, TPointCustomFields } from '../points.model';

export function generatePoint(
  point: TPointResponse | TPoint,
  customFields: TPointCustomFields,
): TPoint {
  const generatedPoint: TPoint = {
    header: point.header,
    customFieldsMap: customFields,
    description: point.description,
    descriptionRich: point.descriptionRich,
    pins: point.pins ? point.pins : [],
    polygons: point.polygons,
    priority: point.priority,
    status: point.status,
    sequenceNumber: point.sequenceNumber,
    title: point.title,
    tags: point.tags,
    assignees: point.assignees ? point.assignees : [],
    _id: point._id,
    workspaceRef: point.workspaceRef,
    containsAttachments: point.containsAttachments,
    fullyInitialized: true,
    flagged: point.flagged ? point.flagged : false,
  };

  return generatedPoint;
}
