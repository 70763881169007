import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReminderSnoozeButtonService {
  constructor() {}

  approximateDateToFull15Minutes(date: Date): Date {
    const minutes = date.getMinutes();
    const remainder = minutes % 15;
    const roundedMinutes = remainder >= 8 ? minutes + (15 - remainder) : minutes - remainder;

    const newDate = new Date(date);
    newDate.setMinutes(roundedMinutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
  }
}
