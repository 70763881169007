<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout>
  <section class="authBox">
    <header>
      <h4>{{ 'create_login_details' | ppTranslate }}</h4>
    </header>

    <div class="authBox__input-wrapper">
      <label class="sr-only" for="new-signup-name">{{ 'create_username' | ppTranslate }}</label>
      <pp-icon
        [ppSrc]="EIconPath.ICON_MISC_PROFILE_18"
        ppColor="grey-600"
        alt="outline of a user in a circle"
        ppClass="setSize18"
      ></pp-icon>

      <input
        id="recover-name"
        class="authBox__input"
        type="text"
        [placeholder]="'create_username' | ppTranslate"
        [(ngModel)]="user.userName"
        name="fullName"
        (keydown.enter)="blurInput($event)"
        autocomplete="off"
      />
    </div>

    <form name="resetForm" class="authBox__inputs-container" novalidate>
      <ng-container *ngIf="user.email">
        <div class="authBox__input-wrapper">
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
            alt="outline of a letter"
            ppColor="grey-600"
            ppClass="setSize18"
          ></pp-icon>

          <input type="text" class="authBox__input--disabled" value="{{ user.email }}" readonly />
        </div>
      </ng-container>

      <div class="authBox__input-wrapper">
        <label class="sr-only" for="new-signup-password">
          {{ 'create_password' | ppTranslate }}
        </label>
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
          alt="outline of a lock"
          ppColor="grey-600"
          ppClass="setSize18"
        ></pp-icon>

        <input
          class="authBox__input"
          [type]="passInputType"
          [placeholder]="'create_password' | ppTranslate"
          [(ngModel)]="user.passwordHash"
          name="password"
          autocomplete="new-password"
          (keydown.enter)="blurInput($event)"
          (keyup)="setInputDirty()"
          required
        />

        <pp-password-visibility
          (ppVisibilityChange)="passInputType = $event"
        ></pp-password-visibility>
      </div>

      <div class="authBox__input-wrapper">
        <label class="sr-only" for="new-signup-password">
          {{ 'confirm_password' | ppTranslate }}
        </label>
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
          alt="outline of a lock"
          ppColor="grey-600"
          ppClass="setSize18"
        ></pp-icon>

        <input
          class="authBox__input"
          [type]="passInputType"
          [placeholder]="'confirm_password' | ppTranslate"
          [(ngModel)]="confirmPassword"
          name="password"
          (keydown.enter)="blurInput($event)"
          (keyup)="setInputDirty()"
        />

        <pp-icon
          *ngIf="user?.passwordHash !== confirmPassword && confirmPassword.length > 0"
          [ppSrc]="EIconPath.ICON_MISC_INFO"
          alt="outline of a lock"
          ppColor="grey-600"
          ppClass="setSize18"
          ppTooltip
          [ppTitle]="'passwords_dont_match' | ppTranslate"
          [ppMobileTooltip]="true"
        ></pp-icon>
      </div>

      <pp-password-validation
        *ngIf="inputDirty"
        (ppValidate)="validatePassword($event)"
        [ppPassword]="user?.passwordHash"
      ></pp-password-validation>

      <div class="authBox__inputs-container">
        <div class="authBox__cb-container">
          <pp-checkbox
            [ppChecked]="acceptedTerms"
            ppType="solid"
            (ppOnValueChange)="toggleAcceptedTerms()"
          ></pp-checkbox>
          <label class="authBox__label">
            {{ 'accept' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/terms-and-conditions"
              target="_blank"
            >
              {{ 'terms_and_conditions' | ppTranslate }}
            </a>
            {{ 'and' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/privacy-policy"
              target="_blank"
            >
              {{ 'privacy_policy' | ppTranslate }}
            </a>
          </label>
        </div>

        <div class="authBox__cb-container">
          <pp-checkbox
            [ppChecked]="acceptedNewsletter"
            ppType="solid"
            (ppOnValueChange)="toggleNewsletter()"
          ></pp-checkbox>
          <label class="authBox__label" for="acceptedNewsletter">
            {{ 'keep_me_updated' | ppTranslate }}
          </label>
        </div>
      </div>
    </form>

    <footer class="authBox__footer--gap-l">
      <pp-button
        ppStyle="primary"
        ppId="updatePasswordSignInBtn"
        [ppProcess]="processing"
        [ppDisabled]="
          !user.userName ||
          !user.passwordHash ||
          !acceptedTerms ||
          !correctPassword ||
          user?.passwordHash !== confirmPassword
        "
        (ppAction)="createLoginDetails()"
      >
        {{ 'sign_up' | ppTranslate }}
      </pp-button>

      <a class="authBox__return-link" routerLink="/login">
        <pp-icon
          [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
          ppColor="grey-600"
          alt="black arrow pointing back"
        ></pp-icon>
        {{ 'return_to_login' | ppTranslate }}
      </a>
    </footer>
  </section>
</pp-not-authorized-layout>
