<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout>
  <section class="authBox">
    <ng-container *ngIf="requestSent">
      <header>
        <h4 class="authBox__title">{{ 'check_your_email' | ppTranslate }}</h4>
      </header>

      <p class="authBox__info-copy">
        {{ 'password_reset_1' | ppTranslate }}
      </p>

      <p class="authBox__info-copy--small">
        {{ 'password_reset_2' | ppTranslate }}
        {{ 'password_reset_3' | ppTranslate }}
      </p>
    </ng-container>
    <ng-container *ngIf="!requestSent">
      <header>
        <h4 class="authBox__title">{{ 'forgot_password' | ppTranslate }}</h4>
        <p class="authBox__copy">{{ 'password_reset_4' | ppTranslate }}</p>
      </header>

      <div class="authBox__input-wrapper">
        <label class="sr-only">{{ 'email' | ppTranslate }}</label>
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
          alt="outline of a letter"
          ppColor="grey-600"
          ppClass="setSize18"
        ></pp-icon>
        <input
          class="authBox__input"
          name="resetPasswordEmail"
          type="email"
          ppFocus
          [placeholder]="'enter_your_email' | ppTranslate"
          [(ngModel)]="email"
          autofocus
          (keydown.enter)="blurInput($event); resetPassword()"
          required
        />
      </div>
    </ng-container>
    <footer class="authBox__footer">
      <pp-button
        *ngIf="!requestSent"
        ppStyle="primary"
        ppId="resetPasswordBtn"
        [ppProcess]="processing"
        (ppAction)="resetPassword()"
      >
        {{ 'reset_password' | ppTranslate }}
      </pp-button>
      <a class="authBox__return-link" routerLink="/login">
        <pp-icon
          [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
          ppColor="grey-600"
          alt="black arrow pointing back"
        ></pp-icon>
        {{ 'return_to_log_in' | ppTranslate }}
      </a>
    </footer>
  </section>
</pp-not-authorized-layout>
