import { TPoint } from '../../../points/points.model';
import { TReminder } from '../../../reminders/reminders.consts';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterReminder } from '../../models/advanced-filter.model';

export function filterReminderAdvanced(
  point: TPoint,
  filter: TAdvancedFilterReminder,
  reminders?: TReminder[],
): boolean {
  const filterValue = filter.value?.length ? filter.value : [null, null];
  const fieldValue = reminders.filter((reminder) => reminder.pointId === point._id);

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue.length;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue.length;
    default:
      break;
  }

  for (let reminder of fieldValue) {
    const fieldValueToFilter = reminder.timestampEpochMillis;
    let match = false;

    switch (filter.option) {
      case EAdvancedFilterOptions.IS:
        match = !filterValue[0] || fieldValueToFilter === filterValue[0];
        break;
      case EAdvancedFilterOptions.IS_BEFORE:
        match = !filterValue[0] || fieldValueToFilter < filterValue[0];
        break;
      case EAdvancedFilterOptions.IS_AFTER:
        match = !filterValue[0] || fieldValueToFilter > filterValue[0];
        break;
      case EAdvancedFilterOptions.IS_BETWEEN:
        match =
          filterValue[0] &&
          filterValue[1] &&
          fieldValueToFilter > filterValue[0] &&
          fieldValueToFilter < filterValue[1];
        break;
    }

    if (match) {
      return true;
    }
  }

  return false;
}
