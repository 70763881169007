<div class="full-height">
  <pp-common-components></pp-common-components>

  <div
    id="site"
    class="site"
    [class.site--no-sidebar]="sidePanel?.hidden"
    [class.site--down]="ui?.actionBar"
    *ngIf="!offline"
  >
    <div id="siteHeader" data-m-target="Work list menu" class="site__header">
      <pp-site-options [ppDataFetched]="dataFetched" [ppIsOverview]="true"></pp-site-options>
    </div>

    <div class="site__body">
      <div data-m-target="Work list table" class="site__table-wrapper">
        <pp-site-table-overview
          *ngIf="dataFetched"
          (ppTableCreated)="hidePlaceholder()"
        ></pp-site-table-overview>

        <div *ngIf="!tableCreated || !dataFetched">
          <div class="site__table-overlay">
            <pp-site-table-placeholder></pp-site-table-placeholder>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="site overview--disabled" *ngIf="offline">
    Overview is not available in offline mode.
  </div>
</div>

<router-outlet name="full"></router-outlet>
