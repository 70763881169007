<div
  class="reminderAlerts"
  data-test="reminderPopup"
  *ngIf="remindersList.length && remindersPopupEnabled"
>
  <div class="remindersList">
    <ng-content
      *ngFor="
        let reminder of remindersList;
        let first = first;
        let last = last;
        trackBy: remindersTrackBy
      "
    >
      <pp-reminder-alert
        [ppHighlighted]="reminder.reminderId === highlightedReminder.reminderId"
        [ppReminder]="reminder"
        (ppSelect)="selectReminder(reminder)"
      ></pp-reminder-alert>

      <div class="reminderSeparator" *ngIf="!last">
        <div class="reminderSeparator__line"></div>
      </div>
    </ng-content>
  </div>

  <div class="reminderButtons">
    <pp-reminder-snooze-button
      [ppReminders]="remindersList"
      [ppReminder]="highlightedReminder"
    ></pp-reminder-snooze-button>

    <pp-reminder-dismiss-button
      [ppReminders]="remindersList"
      [ppReminder]="highlightedReminder"
    ></pp-reminder-dismiss-button>
  </div>
</div>
