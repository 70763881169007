import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EAccountFeature } from 'src/app/project/shared/enums/account-features-enums';

import { cloneDeep } from 'lodash';
import { translate } from 'src/app/project/features/translate/translate';
import { getDefaultAccountFeatures } from 'src/app/project/modules/account/account-utils/default-account-features';
import { TAccount, TAccountFeatures } from 'src/app/project/modules/account/account.model';

@Component({
  selector: 'pp-account-options-features',
  templateUrl: './account-options-features.component.html',
  styleUrls: ['./account-options-features.component.scss'],
})
export class AccountOptionsFeaturesComponent implements OnChanges {
  @Input() readonly ppAccount: TAccount;
  @Output() ppUpdateFeatures = new EventEmitter<TAccountFeatures>();
  @Output() ppAccountChange = new EventEmitter<TAccount>();

  features = getDefaultAccountFeatures();

  featureList = [
    { label: translate('global_table_preferences'), value: EAccountFeature.globalSitePreferences },
    { label: translate('offline'), value: EAccountFeature.offline },
    { label: translate('luerssen_signature'), value: EAccountFeature.pdfSignatures },
    { label: translate('timeline'), value: EAccountFeature.timeline },
    { label: translate('aal_disable_site_plan'), value: EAccountFeature.aalSitePlan },
    { label: translate('aal_disable_export'), value: EAccountFeature.aalExport },
    { label: translate('yco_pdf_layout'), value: EAccountFeature.YCO_PDF_LAYOUT },
    { label: translate('saved_views'), value: EAccountFeature.SAVED_VIEWS },
    { label: translate('advanced_filters'), value: EAccountFeature.ADVANCED_FILTERS },
    { label: translate('fleet_management'), value: EAccountFeature.FLEET_MANAGEMENT },
  ];

  ngOnChanges(): void {
    this.trySetFeatures();
  }

  toggleFeature(feature: string): void {
    this.features[feature] = !this.features[feature];

    this.ppUpdateFeatures.emit(this.features);
  }

  private trySetFeatures(): void {
    if (this.ppAccount.accountFeatures) {
      this.features = cloneDeep(this.ppAccount.accountFeatures);
    }
  }
}
