import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';

export const findPropertyPath = (_field): string => {
  switch (_field) {
    case EBasicField.STATUS:
      return 'status';
    case EBasicField.PRIORITY:
    case 'PRIORITY':
      return 'priority';
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return 'sequenceNumber';
    case EBasicField.TITLE:
      return 'title';
    case EBasicField.TAGS:
      return 'tags';
    case EBasicField.DESCRIPTION:
      return 'description';
    case EBasicField.ASSIGNEES:
      return 'assignees';
    case EBasicField.CREATED:
      return 'header.createdEpochMillis';
    case EBasicField.CREATED_BY:
      return 'header.createdBy';
    case EBasicField.UPDATED:
      return 'header.updatedEpochMillis';
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return 'flagged';
    default:
      return null;
  }
};
