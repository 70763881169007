import { Injectable } from '@angular/core';

import { Observable, Subject, throwError } from 'rxjs';

import { ResponseErrorService } from '../errors/response-error.service';
import { OfflineService } from '../offline/offline.service';

import { NotificationsApiProviderService, PointsApiProviderService } from '@core/api';
import { TGuid } from '@core/helpers';
import { catchError, tap } from 'rxjs/operators';
import { TPointDetails } from '../../view-models/points-details-response.model';
import { TResponseEntity } from '../../view-models/response.models';
@Injectable({
  providedIn: 'root',
})
export class PointsDetailsService {
  public readonly reactionsChange$ = new Subject<TGuid | void>();

  private detailsList: {
    [_id: string]: TPointDetails;
  } = {};

  constructor(
    private responseErrorService: ResponseErrorService,
    private offlineService: OfflineService,
    private notificationsApiProviderService: NotificationsApiProviderService,
    private pointsApiProviderService: PointsApiProviderService,
  ) {}

  fetchPointDetails(_id: TGuid): Observable<TPointDetails> {
    return this.pointsApiProviderService.fetchPointDetails(_id).pipe(
      tap((response) => {
        if (response) {
          this.setPointDetails(_id, response);
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  setPointDetails(_id: string, pointDetails: TPointDetails): void {
    this.detailsList[_id] = pointDetails;
  }

  setPointSubscription(_id: TGuid, subscription: boolean): void {
    this.detailsList[_id].pushNotificationsSubscription = subscription;
  }

  getPointDetails(_id: TGuid): TPointDetails {
    if (this.detailsList[_id]) {
      return this.detailsList[_id];
    } else {
      return {
        pushNotificationsSubscription: false,
        viewersByIds: {},
      };
    }
  }

  clearPointsDetails(): void {
    this.detailsList = {};
  }

  togglePointSubscription(_id: TGuid): Observable<TResponseEntity | never> {
    const subscription = this.getPointDetails(_id).pushNotificationsSubscription;

    return this.subscribeToPointNotifications(_id, !subscription);
  }

  subscribeToPointNotifications(_id: TGuid, status: boolean): Observable<TResponseEntity | never> {
    const offline = this.offlineService.getOffline();

    if (offline) {
      console.log('Updating notifications status failed');

      return throwError('Updating notifications status failed');
    }

    return this.notificationsApiProviderService.subscribeToPointNotifications(_id, status, {}).pipe(
      tap(() => {
        this.setPointSubscription(_id, status);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }
}
