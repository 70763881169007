<div
  id="filterDropdown"
  class="siteFilters__dropdown"
  *ngIf="filters"
  (ppOnRendered)="onFilterDropdownRendered($event)"
>
  <pp-button-dropdown
    [ppSrc]="EIconPath.ICON_TABLE_FILTER"
    [ppText]="'filters' | ppTranslate"
    [ppNumber]="filterNumber"
    [ppActive]="filterNumber.length > 0 || isDropdownVisible"
    (ppAction)="toggleDropdown()"
    data-m-target="Filter dropdown button"
    id="filterDropdownBtn"
    [ppDisabled]="(customFields | keyvalue).length === 0 || !ppDataFetched"
  ></pp-button-dropdown>

  <pp-filter-dropdown
    [ppShow]="isDropdownVisible"
    ppAlign="left"
    [ppFullWidth]="true"
    [ppMinWidth]="advancedFilter?.enabled ? '72.2rem' : '49rem'"
  >
    <header
      class="filterDropdown__header"
      [ngClass]="{ 'filterDropdown__header--advanced': advancedFilter?.enabled }"
      *ngIf="isDropdownVisible"
    >
      <div class="filtersViewHeader">
        <h6 class="filterView__title">
          {{ 'filter_by' | ppTranslate }}
          <pp-icon
            ppTooltip
            [ppTitle]="'advanced_filter_tooltip' | ppTranslate"
            [ppSrc]="EIconPath.ICON_BADGE_INFO"
            ppColor="grey-600"
            ppClass="setSize14"
            [ppMobileTooltip]="true"
          ></pp-icon>
        </h6>

        <pp-saved-view-preview-header
          *ngIf="savedViewsEnabled"
          [ppWorkspaceId]="workspaceId"
          (ppSaveToCurrentView)="saveFiltersToView()"
          [ppProcessing]="saveViewProcessing"
        ></pp-saved-view-preview-header>
      </div>
    </header>

    <div #filterDropdown class="popupFilters__container">
      <pp-basic-filters
        [ppFilters]="filters"
        [ppWorkspaces]="workspaces"
        [ppSitePlanExists]="sitePlanExists"
        [ppTagsVisible]="tagsVisible"
        [ppIsDropdownVisible]="isDropdownVisible"
        [ppWorkspaceId]="workspaceId"
        *ngIf="!advancedFilter?.enabled"
      ></pp-basic-filters>

      <pp-advanced-filters-dropdown
        [ppWorkspaceId]="workspaceId"
        *ngIf="advancedFilter?.enabled && isDropdownVisible"
      ></pp-advanced-filters-dropdown>
    </div>

    <footer
      class="filterDropdown__footer"
      [class.filterDropdown__footer--basicOnly]="!advancedFiltersEnabled"
      [class.filterDropdown__section--basic]="
        !workspaceId ||
        workspaceId === EWorkspaces.OVERVIEW ||
        workspaceId === EWorkspaces.TIMELINE ||
        workspaceId === EWorkspaces.REMINDERS
      "
    >
      <pp-button
        ppStyle="secondary"
        ppId="filterDropdownAdvancedBtn"
        (ppAction)="switchToAdvanced()"
        ppBackground="white"
        *ngIf="advancedFiltersEnabled"
        ppSize="large"
      >
        {{
          (advancedFilter?.enabled ? 'switch_to_basic_filters' : 'switch_to_advanced_filters')
            | ppTranslate
        }}
      </pp-button>

      <div class="filterDropdown__buttons">
        <button
          id="filterDropdownCancelBtn"
          class="filterDropdown__cancel"
          (click)="clearFilters()"
        >
          {{ 'clear_all' | ppTranslate }}
        </button>

        <pp-button
          ppStyle="primary"
          ppSize="large"
          ppId="filterDropdownApplyBtn"
          (ppAction)="hideDropdown()"
        >
          {{ 'done' | ppTranslate }}
        </pp-button>
      </div>
    </footer>
  </pp-filter-dropdown>
</div>
