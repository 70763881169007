<div
  *ngIf="!(ppListView && !visible)"
  class="calendarEvent"
  [class.calendarEvent--dismissed]="isDismissed"
  [class.calendarEvent--overdue]="isOverdue"
  [class.calendarEvent--hidden]="!visible"
>
  <div
    class="calendarEvent__inner"
    [class.calendarEvent__inner--daily]="ppDailyView"
    (click)="openReminder()"
    [class.calendarEvent__inner--snoozed]="isSnoozed"
  >
    <p
      class="calendarEvent__name"
      [class.calendarEvent__name--dismissed]="isDismissed"
      [class.calendarEvent__name--overdue]="isOverdue"
      ppTooltip
      [ppTitle]="ppReminder.name"
      [ppTrimTooltip]="true"
    >
      {{ ppReminder.name }}
    </p>
    <span class="calendarEvent__timestamp">{{ formattedTimestamp }}</span>
  </div>

  <section
    class="calendarEvent__pointRef"
    [class.calendarEvent__pointRef--daily]="ppDailyView"
    (click)="openPoint()"
  >
    <section class="reminderSnoozeSection" *ngIf="isSnoozed">
      <pp-icon
        ppTooltip
        [ppTitle]="visible ? snoozedTimestamp : ''"
        [ppSrc]="EIconPath.ICON_MISC_SNOOZE_18"
        class="reminderSnoozeIcon"
        ppClass="setSize18"
        ppColor="inherit"
      ></pp-icon>

      <span class="reminderSnoozeTimestamp" *ngIf="ppDailyView">{{ snoozedTimestamp }}</span>
    </section>

    <pp-reminder-point-reference
      *ngIf="ppDailyView"
      [ppReminder]="ppReminder"
      [ppBrightMode]="true"
    ></pp-reminder-point-reference>

    <div
      *ngIf="!ppDailyView"
      (click)="openPoint()"
      [ppInteractive]="true"
      [ppOptions]="tooltipOptions"
      ppTooltip
      [ppTitle]="visible ? pointTooltip : ''"
      [ppSanitize]="false"
      class="calendarEvent__priority"
      [class.calendarEvent__priority--LOW]="ppReminder.pointPriority === EPriority.LOW"
      [class.calendarEvent__priority--MEDIUM]="ppReminder.pointPriority === EPriority.MEDIUM"
      [class.calendarEvent__priority--HIGH]="ppReminder.pointPriority === EPriority.HIGH"
    ></div>
  </section>
</div>
