import { Injectable } from '@angular/core';
import { logDevMessage } from '@core/helpers';
import { Store } from '@ngrx/store';
import { IMessage } from '@stomp/stompjs';
import { SidePanelService } from '../layout/side-panel/side-panel.service';
import { NotificationsService } from '../notifications/notifications.service';
import { UpdateUserUnreadNotifications } from '../user/user.actions';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SocketsNotificationsService {
  constructor(
    private store: Store,
    private notificationsService: NotificationsService,
    private sidePanelService: SidePanelService,
    private userService: UserService,
  ) {}

  subscribeCallback(_message: IMessage): void {
    this.logDevMessage('Websocket - received a message in notifications');
    this.logDevMessage(_message);

    if (_message.body) {
      if (!this.sidePanelService.getSidePanel().notificationsExpanded) {
        this.store.dispatch(new UpdateUserUnreadNotifications(true));
      }

      this.notificationsService.checkForNewNotifications();
    } else {
      this.logDevMessage('Websocket - received an empty notifications message');
    }
  }

  private logDevMessage(message): void {
    const user = this.userService.getUser();

    logDevMessage(user, message);
  }
}
