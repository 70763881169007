import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { SyncService } from '../../offline/sync.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../workspace/workspace.service';

import { TUISettings } from '../../ui/ui.model';

import { Title } from '@angular/platform-browser';
import { SetActiveWorkspaceId } from 'src/app/project/services/active/active.actions';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { EStore } from '../../../shared/enums/store.enum';
import { SitePointFilterService } from '../../filters/site-point-filter.service';
import { TSidePanel } from '../../layout/side-panel/side-panel.model';
import { PointsFetchingService } from '../../points/points-fetching.service';
import { RemindersService } from '../reminders.service';

@Component({
  selector: 'pp-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
})
export class RemindersComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  filterDataFetched = false;

  ui$: Observable<TUISettings>;
  ui: TUISettings;
  routerPath = this.router.url;

  constructor(
    private store: Store<{
      ui: TUISettings;
    }>,
    private sidePanelService: SidePanelService,
    private workspaceService: WorkspaceService,
    private syncService: SyncService,
    private preferencesService: PreferencesService,
    private translationPipe: TranslationPipe,
    private router: Router,
    private titleService: Title,
    private pointsFetchingService: PointsFetchingService,
    private sitePointFilterService: SitePointFilterService,
    private remindersService: RemindersService,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerPath = event.url;
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.translationPipe.transform('reminders') + ' | Pinpoint Works');

    this.sidePanelService.initSidePanel({ expandedInitially: false });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });

    this.firstLoad();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private firstLoad(): void {
    this.store.dispatch(new SetActiveWorkspaceId(null));

    this.syncService
      .firstLoad()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.workspaceService.generateWorkspaces()),
        switchMap(() => this.preferencesService.fetchPreferences()),
        switchMap(() => {
          const reminders = this.remindersService.getReminders();

          const reminderPoints = reminders
            .map((reminder) => reminder.pointId)
            .filter((pointId) => !!pointId);

          return this.pointsFetchingService.fetchPointsByIds(reminderPoints);
        }),
        tap(() => {
          this.sitePointFilterService.filterPoints();
          this.filterDataFetched = true;
        }),
      )
      .subscribe();
  }
}
