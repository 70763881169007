<div class="reminderDismissDropdown">
  <div class="reminderDismissDropdown__options">
    <div class="reminderDismissDropdown__option" (click)="selectOption(false)">
      {{ 'dismiss' | ppTranslate }}
    </div>

    <div class="reminderDismissDropdown__option" (click)="selectOption(true)">
      {{ 'dismiss_all' | ppTranslate }}
    </div>
  </div>
</div>
