<div class="reminders__header">
  <pp-button (ppAction)="back()" ppStyle="text" *ngIf="!ppHide">
    <pp-icon
      [ppSrc]="EIconPath.ARROW_LEFT"
      ppColor="primary-600"
      [ppDisplayContents]="true"
    ></pp-icon>
    {{ 'back_to_point' | ppTranslate }}
  </pp-button>
  <span class="reminders__headerText">{{ 'reminders_on_point' | ppTranslate }}</span>
  <pp-button data-test="setNewReminderButton" (ppAction)="newReminder()" ppStyle="primary">
    + {{ 'set_reminder' | ppTranslate }}
  </pp-button>
</div>
