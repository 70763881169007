import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { EAdvancedFilterType } from '../../models/advanced-filter-type.enum';
import {
  TAdvancedFilterDate,
  TAdvancedFilterDateCreated,
  TAdvancedFilterDateUpdated,
} from '../../models/advanced-filter.model';

export function filterDateAdvanced(
  point: TPoint,
  filter: TAdvancedFilterDate | TAdvancedFilterDateCreated | TAdvancedFilterDateUpdated,
): boolean {
  const filterValue = filter.value?.length ? filter.value : [null, null];

  let fieldValue: number;

  switch (filter.type) {
    case EAdvancedFilterType.DATE:
      fieldValue = point.customFieldsMap?.[filter.id]?.value;
      break;
    case EAdvancedFilterType.DATE_CREATED:
      fieldValue = point.header.createdEpochMillis;
      break;
    case EAdvancedFilterType.DATE_UPDATED:
      fieldValue = point.header.updatedEpochMillis;
      break;
  }

  let fieldValueToFilter: number;

  if (fieldValue) {
    const date = new Date(+fieldValue);
    date.setHours(0, 0, 0, 0);
    fieldValueToFilter = date.getTime();
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return !filterValue[0] || fieldValueToFilter === filterValue[0];
    case EAdvancedFilterOptions.IS_BEFORE:
      return !filterValue[0] || fieldValueToFilter < filterValue[0];
    case EAdvancedFilterOptions.IS_AFTER:
      return !filterValue[0] || fieldValueToFilter > filterValue[0];
    case EAdvancedFilterOptions.IS_BETWEEN:
      return (
        filterValue[0] &&
        filterValue[1] &&
        fieldValueToFilter > filterValue[0] &&
        fieldValueToFilter < filterValue[1]
      );
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValueToFilter;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValueToFilter;
  }
}
