import dayjs from 'dayjs';

import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

export function getReminderDateTime(timestamp: number, format: EDateFormat): string {
  let inputHourFormat = ' [at] hh:mm A';

  if (format === EDateFormat.EUROPEAN || format === EDateFormat.ISO) {
    inputHourFormat = ' [at] HH:mm';
  }

  const date = dayjs(timestamp);
  const today = dayjs();

  if (date.isSame(today, 'day')) {
    return `Today, ${date.format(inputHourFormat)}`;
  } else {
    return dayjs(timestamp).format('ddd D MMM' + inputHourFormat);
  }
}
