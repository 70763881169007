<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon
        [ppSrc]="EIconPath.ICON_FIELDS_COST_18"
        ppColor="inherit"
        ppClass="setSize18"
        *ngIf="ppVolyActive === EIntegrationStatus.OFF"
      />
      <img
        [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG"
        *ngIf="ppVolyActive === EIntegrationStatus.ON"
        ppTooltip
        [ppTitle]="'custom_field_voly_tooltip' | ppTranslate"
      />
      <img
        [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_BIG"
        *ngIf="ppVolyActive === EIntegrationStatus.DISABLED"
        ppTooltip
        [ppTitle]="'custom_field_voly_disabled_tooltip' | ppTranslate"
      />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <pp-custom-field-numeric-with-subfields
    [ppPointField]="ppPointCustomField"
    [ppFieldId]="ppFieldId"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppCanEdit]="ppCanEdit"
    [ppPointId]="ppPointId"
    [ppNew]="ppNew"
    *ngIf="ppSubfieldsActive"
  ></pp-custom-field-numeric-with-subfields>

  <pp-custom-field-cost
    [ppWorkspaceId]="ppWorkspaceId"
    [ppPointId]="ppPointId"
    [ppFieldId]="ppFieldId"
    [ppFieldLabel]="ppFieldLabel"
    [ppFieldCurrencySymbol]="ppFieldCurrencySymbol"
    [ppFieldCurrencyCode]="ppFieldCurrencyCode"
    [ppCurrencyName]="currencyName"
    [ppFieldValue]="ppPointCustomField?.value"
    [ppIntegrationStatus]="ppVolyActive"
    [ppNew]="ppNew"
    [ppCanEdit]="ppCanEdit"
    (ppUpdate)="updateField($event)"
    [ppDecimalPlaces]="2"
    *ngIf="!ppSubfieldsActive"
  ></pp-custom-field-cost>
</div>
