import { Client, IFrame } from '@stomp/stompjs';

import { Inject, Injectable } from '@angular/core';

import { logDevMessage } from '@core/helpers';
import { getAuthHeaders } from 'src/app/core/http/user-auth';
import { EInstance } from '../../shared/enums/instances';
import { UserService } from '../user/user.service';
import { EUserType } from '../users/user-types-enum';
import { SocketsNotificationsService } from './sockets-notifications.service';
import { SocketsRemindersService } from './sockets-reminders.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private client: Client;
  private connectUrl = '';
  private outgoingHeartbeatMs = 29 * 1000;
  private incomingHeartbeatMs = 0;
  private reconnectDelayMs = 60 * 1000;
  private connectionTimeoutMs = 30 * 1000;

  private notificationsUrl = '/user/topic/notifications';
  private remindersUrl = '/user/topic/reminders';

  constructor(
    @Inject('HOSTNAME') private host: string,
    private userService: UserService,
    private socketsNotificationsService: SocketsNotificationsService,
    private socketsRemindersService: SocketsRemindersService,
  ) {
    this.connectUrl = `wss://${this.host}/api/v1/ws-endpoint`;

    if (this.host === 'localhost:4200') {
      this.connectUrl = `wss://${EInstance.CLONE}/api/v1/ws-endpoint`; // localhost clone
    }

    // this.connectUrl = `wss://${EInstance.CLONE}/api/v1/ws-endpoint`; // localhost clone
    // this.connectUrl = `wss://${EInstance.CLONE2}/api/v1/ws-endpoint`; // localhost clone2
    // this.connectUrl = `wss://${EInstance.CLONE3}/api/v1/ws-endpoint`; // localhost clone3
    // this.connectUrl = 'ws://localhost:8080/api/v1/ws-endpoint'; // For local backend
  }

  connect(): void {
    if (!this.client) {
      const user = this.userService.getUser();

      this.client = new Client({
        brokerURL: this.connectUrl,
        connectHeaders: getAuthHeaders(),
        heartbeatIncoming: this.incomingHeartbeatMs,
        heartbeatOutgoing: this.outgoingHeartbeatMs,
        reconnectDelay: this.reconnectDelayMs,
        connectionTimeout: this.connectionTimeoutMs,
      });

      if (user.userType !== EUserType.DEVELOPER && user.userType !== EUserType.TESTER) {
        this.client.debug = (): void => {};
      }

      this.client.onConnect = (frame): void => {
        this.logDevMessage('Websocket - connected');
        this.logDevMessage(frame);
        this.connectCallback();
      };

      this.client.onStompError = (error): void => {
        this.errorCallback(error);
      };

      this.client.onWebSocketError = (error: any): void => {
        this.errorCallback(error);
      };

      this.client.onWebSocketClose = (closeEvent: CloseEvent): void => {
        this.logDevMessage('Websocket - closed');
        this.logDevMessage(closeEvent);
      };

      this.client.activate();
    }
  }

  disconnect(): void {
    if (this.client) {
      this.client.deactivate();
    }

    this.client = null;
  }

  private errorCallback(error: IFrame): void {
    this.logDevMessage(error);

    this.disconnect();
  }

  private connectCallback(): void {
    if (!this.client) {
      return;
    }

    this.logDevMessage('Connected');

    this.client.subscribe(this.notificationsUrl, (message) =>
      this.socketsNotificationsService.subscribeCallback(message),
    );

    this.client.subscribe(this.remindersUrl, (message) =>
      this.socketsRemindersService.subscribeCallback(message),
    );

    this.socketsRemindersService.checkForRemindersAfterReconnect();
  }

  private logDevMessage(message): void {
    const user = this.userService.getUser();

    logDevMessage(user, message);
  }
}
