<div class="full-height">
  <pp-common-components></pp-common-components>

  <div
    class="settingsContent"
    [class.settingContent--no-sidebar]="sidePanel.hidden"
    [class.settingsContent--collapsed-sidebar]="!sidePanel.expandedInitially && !sidePanel.hidden"
    [class.settingsContent--down]="ui.actionBar"
  >
    <div class="settingsContent__container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
